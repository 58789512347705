import { generateOptionVariants } from "@lb/utils";
import { useFormikContext } from "formik";
import { find, includes, isEmpty, size } from "lodash";
import React, { useEffect, useMemo, useState } from "react";

const TAB_OPTIONS = generateOptionVariants({
  variables: "Variables",
  workflows: "Workflows",
  test: "Test",
});

const engine =
  (Component) =>
  ({ ...props }) => {
    const { searchParams, setSearchParams } = props;
    const [selectedVariable, setSelectedVariable] = useState();
    const { values } = useFormikContext();

    const tab = {
      selected: useMemo(
        () =>
          includes(TAB_OPTIONS.keys, searchParams.tab)
            ? searchParams.tab
            : TAB_OPTIONS.key.VARIABLES,
        [searchParams.tab]
      ),
      select: (v) => setSearchParams({ ...searchParams, tab: v }),
    };

    useEffect(() => {
      if (selectedVariable && isEmpty(find(values.variables, { name: selectedVariable }))) {
        setSelectedVariable(null);
      }
    }, [size(values.variables), selectedVariable]);

    return <Component {...props} {...{ tab, TAB_OPTIONS }} />;
  };

export default engine;
