import React, { useMemo } from "react";
import { useTheme } from "@mui/material";
import { useFormikContext } from "formik";

export function generateWorkflowName() {
  const prefixes = ["Rule", "Logic", "Decision", "Action", "Task", "Process", "Flow", "Event", "Chain", "Path"];
  const infixes = ["Flow", "Stream", "Chain", "Map", "Sync", "Orchestrator", "Cycle", "Loop", "Grid", "Engine"];
  const suffixes = ["Processor", "Router", "Executor", "Manager", "Coordinator", "Handler", "Director", "Automator"];

  const randomPrefix = prefixes[Math.floor(Math.random() * prefixes.length)];
  const randomInfix = infixes[Math.floor(Math.random() * infixes.length)];
  const randomSuffix = suffixes[Math.floor(Math.random() * suffixes.length)];

  // Randomly decide whether to use 2 or 3 parts in the name
  const useInfix = Math.random() > 0.5; // 50% chance

  if (useInfix) {
    return `${randomPrefix}${randomInfix}${randomSuffix}`;
  } else {
    return `${randomPrefix}${randomSuffix}`;
  }
}

const engine =
  (Component) =>
  ({ ...props }) => {
    const { setSearchParams, searchParams } = props;
    const { values, setFieldValue } = useFormikContext();
    const theme = useTheme();
    const $workflow = {
      selected: useMemo(
        () => Number(searchParams.workflow && searchParams.workflow < values?.workflows?.length ? searchParams.workflow : 0),
        [searchParams.workflow, values?.workflows?.length]
      ),
      select: (w) => setSearchParams({ ...searchParams, workflow: w }),
    };

    const initialValues = {
      workflows: [
        {
          title: "Undefined",
          conjunction: "and",
          rules: [
            {
              source: "",
              operator: "",
              value: "",
            },
            {
              conjunction: "or",
              rules: [
                {
                  source: "",
                  operator: "",
                  value: "",
                },
                {
                  source: "",
                  operator: "",
                  value: "",
                },
                {
                  conjunction: "and",
                  rules: [
                    {
                      source: "",
                      operator: "",
                      value: "",
                    },
                    {
                      source: "",
                      operator: "",
                      value: "",
                    },
                  ],
                },
              ],
            },
          ],
          actions: [
            {
              target: "",
              action: "",
              payload: "",
            },
            {
              target: "",
              action: "",
              payload: "",
            },
          ],
        },
      ],
    };
    
    return <Component {...props} {...{ values, $workflow, setFieldValue, theme }} />;
  };

export default engine;


