import { LbPatternField, LbTextArea, LbTextField } from "@lb/frontend";
import { getInitials } from "@lb/utils";
import { Box, Button, Grid, InputAdornment, Stack, Typography } from "@mui/material";
import { Form, useFormikContext } from "formik";
import React, { Fragment } from "react";
import ReactQuill from "react-quill";

function Metadata({ nextStep }) {
  const { values, setFieldValue } = useFormikContext();
  return (
    <Fragment>
      <Stack height={"100%"} component={Form} mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <LbTextField name="name" label="Template Name" placeholder="Enter template name" />
          </Grid>
          <Grid item md={2}>
            <LbPatternField
              name="abbreviation"
              label="Abbreviation"
              placeholder="Enter abbreviation"
              pattern={"$$$$$$$$$$$$$$"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      disabled={!values?.name}
                      onClick={() => setFieldValue("abbreviation", getInitials(values?.name))}
                      size="small"
                      variant="text"
                      color="info"
                    >
                      Auto
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LbTextArea name="description" label="Template Description" placeholder="Enter template description" />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                ".ql-toolbar": { borderRadius: "8px 8px 0 0" },
                ".ql-container": { borderRadius: "0 0 8px 8px" },
                aspectRatio: 1 / 1.4142,
                display: "inline",
              }}
            >
              <Typography color="text.secondary" variant="body2">
                Content:
              </Typography>

              <ReactQuill
                theme="snow"
                value={values.content}
                onChange={(val) => setFieldValue("content", val)}
                style={{ marginTop: 0 }}
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ align: [] }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["table"],
                  ],
                }}
              />
            </Box>
          </Grid>
        </Grid>
        {/* <LbActionFooter>
          <LbButton type="button" disabled={isSubmitting} onClick={resetForm} color="warning">
            Cancel
          </LbButton>

          <LbButton
            type={!isValid || dirty ? "submit" : "button"}
            loading={isSubmitting}
            disabled={isValidating}
            onClick={!isValid || dirty ? handleSubmit : nextStep}
          >
            {!isValid || dirty ? (isValidating ? "Validating" : "Save") : "Next"}
          </LbButton>
        </LbActionFooter> */}
      </Stack>
    </Fragment>
  );
}

export default Metadata;
