import { LbNumberField, LbSmartSelect, LbSwitch, LbTags, LbTextArea } from "@lb/frontend";
import { VARIABLE_TYPES } from "@lb/utils";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { findIndex, includes } from "lodash";
import React, { useMemo } from "react";
import engine from "./engine";

function EditVariable({ variable }) {
  const { values, setFieldValue } = useFormikContext();

  const VARIABLE_INDEX = useMemo(
    () => findIndex(values.variables, { name: variable?.selected }),
    [values.variables, variable?.selected]
  );

  return (
    <Stack spacing={3} mt={1} pt={0.5}>
      <LbTextArea
        inputProps={{ minRows: 2 }}
        name={`variables[${VARIABLE_INDEX}].label`}
        placeholder="Enter variable title"
        label="Variable title"
        required
      />

      <LbSmartSelect
        name={`variables[${VARIABLE_INDEX}].type`}
        placeholder="Select input type"
        label="Input type"
        options={VARIABLE_TYPES.labelValue}
        required
        callback={(selected) => {
          if (
            includes(
              [VARIABLE_TYPES.key.STATIC, VARIABLE_TYPES.key.TEXT, VARIABLE_TYPES.key.NUMBER],
              selected?.value
            )
          ) {
            setFieldValue(`variables[${VARIABLE_INDEX}].options`, []);
            setFieldValue(`variables[${VARIABLE_INDEX}].multiple`, false);
            setFieldValue(`variables[${VARIABLE_INDEX}].selectAll`, false);
          }

          if (
            selected?.value === VARIABLE_TYPES.key.NUMBER ||
            (selected?.value === VARIABLE_TYPES.key.MCQ &&
              values.variables?.[VARIABLE_INDEX]?.multiple)
          ) {
            setFieldValue(`variables[${VARIABLE_INDEX}].max`, "");
            setFieldValue(`variables[${VARIABLE_INDEX}].min`, "");
          } else {
            setFieldValue(`variables[${VARIABLE_INDEX}].max`, "");
            setFieldValue(`variables[${VARIABLE_INDEX}].min`, "");
          }
        }}
      />

      {((values.variables?.[VARIABLE_INDEX]?.type?.value === VARIABLE_TYPES.key.MCQ &&
        values.variables?.[VARIABLE_INDEX]?.multiple) ||
        values.variables?.[VARIABLE_INDEX]?.type?.value === VARIABLE_TYPES.key.NUMBER) && (
        <Stack direction={"row"} spacing={2}>
          <LbNumberField name={`variables[${VARIABLE_INDEX}].min`} placeholder="MIN" label="Min" />
          <LbNumberField name={`variables[${VARIABLE_INDEX}].max`} placeholder="MAX" label="Max" />
        </Stack>
      )}

      {values.variables?.[VARIABLE_INDEX]?.type?.value === VARIABLE_TYPES.key.MCQ && [
        <LbTags
          required
          name={`variables[${VARIABLE_INDEX}].options`}
          placeholder="Enter options"
          label="Input options"
        />,
        <LbSwitch
          name={`variables[${VARIABLE_INDEX}].multiple`}
          label="Allow multiple selection"
        />,
      ]}

      {values.variables?.[VARIABLE_INDEX]?.multiple &&
        values.variables?.[VARIABLE_INDEX]?.type?.value === VARIABLE_TYPES.key.MCQ && (
          <LbSwitch name={`variables[${VARIABLE_INDEX}].selectAll`} label="Allow select all" />
        )}

      {values.variables?.[VARIABLE_INDEX]?.type?.value === VARIABLE_TYPES.key.STATIC && (
        <LbTextArea
          inputProps={{ minRows: 2 }}
          name={`variables[${VARIABLE_INDEX}].value`}
          placeholder="Enter variable value"
          label="Variable value"
          required={values.variables?.[VARIABLE_INDEX]?.type?.value === VARIABLE_TYPES.key.STATIC}
        />
      )}

      {values.variables?.[VARIABLE_INDEX]?.type?.value !== VARIABLE_TYPES.key.STATIC && (
        <LbTextArea
          inputProps={{ minRows: 1 }}
          name={`variables[${VARIABLE_INDEX}].placeholder`}
          placeholder="Enter input placeholder"
          label="Input placeholder"
        />
      )}

      <LbTextArea
        inputProps={{ minRows: 2 }}
        name={`variables[${VARIABLE_INDEX}].description`}
        placeholder="Enter description"
        label="Variable description"
      />
    </Stack>
  );
}

export default engine(EditVariable);
