import { Paper } from "@mui/material";
import React, { Fragment } from "react";
import engine from "./engine";

function PDFOutput({ pdfContainerRef }) {
  return (
    <Fragment>
      <Paper sx={{ width: "100%", height: "90vh", aspectRatio: "0.707114 / 1", position: "sticky", top: 50 }} ref={pdfContainerRef}></Paper>
    </Fragment>
  );
}

export default engine(PDFOutput);
