import {
  documentsEndpoints,
  getRequest,
  lbDate,
  postRequest,
  putRequest,
  useReactQuery,
} from "@lb/frontend";
import { useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import { isEmpty, join, map, omit, split, toPairs } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { controller } = props;
    const { organizationId } = useParams();
    const queryClient = useQueryClient();

    const {
      data: { data = {} } = {},
      isLoading,
      refetch,
    } = useReactQuery({
      queryKeys: [`documents-${controller._id}`],
      apiCall: () =>
        getRequest({
          url: documentsEndpoints.registry.getById(
            controller._id,
            organizationId
          ),
          baseUrl: "drm",
        }),
      options: {
        enabled: Boolean(controller._id),
        cacheTime: 0,
      },
    });

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        name: "",
        departments: [],
        roles: [],
        description: "",
        eventDate: new Date().toDateString(),
        ...omit(data, ["_id", "__v", "createdAt", "updatedAt", "tags"]),
        transcript: !isEmpty(data)
          ? {
              type: data.type,
              mimetype: data.mimetype,
              filename: data.filename,
            }
          : null,
      },
      validationSchema: Yup.object({
        name: Yup.string().required(),
        eventDate: Yup.string().required(),
        departments: Yup.array().min(1, "at least 1").required("required"),
        roles: Yup.array().min(1, "at least 1").required("required"),
        transcript: Yup.mixed().required("File is required"),
      }),
      onSubmit: async (values, { setSubmitting, resetForm }) => {
        let updatedObject = {};

        //! this will get the updated fields only
        for (const iterator of toPairs(values)) {
          if (data[iterator[0]] !== iterator[1]) {
            updatedObject[iterator[0]] = iterator[1];
          }
        }

        //! Need to depopulate it as formdata value should be string
        if (updatedObject.departments) {
          updatedObject.departments = join(map(values.departments, "_id"), "_");
        }

        if (updatedObject.roles) {
          updatedObject.roles = join(map(values.roles, "_id"), "_");
        }

        var formdata = new FormData();
        for (var key in omit(updatedObject, ["transcript"])) {
          if (updatedObject.hasOwnProperty(key)) {
            formdata.append(key, updatedObject[key]);
          }
        }

        //! change file name
        if (values.transcript instanceof File) {
          const ftype = split(values.transcript.path, ".").pop();
          const fname = `${values.name} ${lbDate(values.eventDate)}.${ftype}`;
          formdata.append("transcript", updatedObject.transcript, fname);
        }

        try {
          await (controller._id ? putRequest : postRequest)({
            url: controller._id
              ? documentsEndpoints.registry.update(
                  controller._id,
                  organizationId
                )
              : documentsEndpoints.registry.upload(organizationId),
            data: formdata,
            baseUrl: "drm",
          });

          queryClient.invalidateQueries({
            queryKey: "documents",
            type: "all",
          });
          if (controller._id) await refetch();
          else resetForm();
        } catch (error) {
        } finally {
          setSubmitting(false);
        }
      },
    });

    const handleClose = () => {
      formik.resetForm();
      controller.set({ open: false, _id: null });
    };

    return (
      <Component
        {...props}
        {...{ handleClose, organizationId, formik, data, isLoading }}
      />
    );
  };

export default engine;
