import { LbActionFooter, LbButton } from "@lb/frontend";
import { Toolbar } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Form, FormikProvider } from "formik";
import React, { Fragment } from "react";
import engine from "./engine";
import PDFOutput from "./PDFOutput";
import WorkPage from "./WorkPage";

function Transcript({ contractFormik, workflows, variables, manuscript, values }) {
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid size={4} pt={2} position={"relative"}>
          <FormikProvider value={contractFormik}>
            <Form>
              <WorkPage {...{ workflows, variables, name: "workpaper" }} />
              <Toolbar variant="dense" />
              <LbActionFooter sx={{ position: "sticky" }} stackProps={{ px: 0 }}>
                <LbButton type="submit" disabled={!contractFormik.dirty} loading={contractFormik.isSubmitting}>
                  Persist
                </LbButton>
              </LbActionFooter>
            </Form>
          </FormikProvider>
        </Grid>
        <Grid size={8}>
          <PDFOutput {...{ manuscript, workpaper: contractFormik.values.workpaper, variables }} />
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default engine(Transcript);
