import { Chip, Stack, Typography } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import { get, map, size } from "lodash";
import React, { Children } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { LuGitCommit } from "react-icons/lu";
import ActionFields from "./ActionFields";
import { FiTrash2 } from "react-icons/fi";
import { LbAlert } from "@lb/frontend";
import swal from "sweetalert";

function Actions({ name }) {
  const { values } = useFormikContext();

  return (
    <FieldArray name={`${name}.actions`}>
      {({ push: pushAction, remove: removeAction, swap }) => (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              position: "relative",
              "&::before": {
                content: '""',
                width: "100%",
                position: "absolute",
                borderTop: `2px dashed`,
                borderColor: "secondary.main",
                top: "50%",
                left: 0,
                transform: "translateY(-50%)",
                zIndex: -1,
              },
            }}
          >
            <Typography variant="h5" fontWeight="bolder" fontFamily="courier" bgcolor="white">
              THEN
            </Typography>
            <Chip
              label="Add Action"
              icon={<LuGitCommit />}
              size="small"
              variant="outlined"
              color="secondary"
              sx={{
                borderWidth: 1.5,
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white!important" },
              }}
              onClick={() => pushAction({ action: "", target: "", payload: "" })}
            />
          </Stack>
          <DragDropContext
            onDragEnd={(result) => swap(result.source.index, result.destination.index)}
          >
            <Droppable droppableId="actions">
              {(provided) => (
                <Stack {...provided.droppableProps} ref={provided.innerRef} spacing={3}>
                  {Children.toArray(
                    map(get(values, `${name}.actions`), (actionInstance, actionIndex) => {
                      return (
                        <Draggable
                          key={actionIndex}
                          draggableId={`action-${actionIndex}`}
                          index={actionIndex}
                        >
                          {(provided) => (
                            <ActionFields
                              name={`${name}.actions.${actionIndex}`}
                              provided={provided}
                              removeAction={
                                size(get(values, `${name}.actions`)) > 1
                                  ? () =>
                                      LbAlert({
                                        title: "Warning",
                                        buttons: ["Cancel", { text: "Confirm", closeModal: false }],
                                        message: "Confirm to delete?",
                                        danger: true,
                                        icon: FiTrash2,
                                      }).then(async (willDelete) => {
                                        if (willDelete) {
                                          removeAction(actionIndex);
                                        }
                                        swal.close();
                                      })
                                  : null
                              }
                            />
                          )}
                        </Draggable>
                      );
                    })
                  )}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </FieldArray>
  );
}

export default Actions;
