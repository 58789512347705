import { lbUid } from "@lb/frontend";
import { AddCircleOutline, DragIndicator, Edit, FileCopyOutlined, HighlightOffOutlined } from "@mui/icons-material";
import { Divider, Icon, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon } from "@mui/material";
import { useFormikContext } from "formik";
import { cloneDeep, map } from "lodash";
import React, { Children, Fragment } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FiTrash2 } from "react-icons/fi";

function WorkflowList({ $workflow, workFlowBody }) {
  const { values, setFieldValue } = useFormikContext();

  const [contextMenu, setContextMenu] = React.useState(null);

  const handleContextMenu = (event, index) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
            index,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(values.workflows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFieldValue("workflows", items);
  };

  const handleDeleteWorkflow = (workflowIndex) => {
    const updatedWorkflows = values.workflows.filter((_, index) => index !== workflowIndex);
    setFieldValue("workflows", updatedWorkflows);
    if ($workflow?.selected === workflowIndex && updatedWorkflows.length > 0) {
      $workflow.select(0);
    } else if (updatedWorkflows.length === 0) {
      $workflow.select(null);
    }
  };

  const handleDuplicateWorkflow = (workflowIndex) => {
    let duplicate = cloneDeep(values?.workflows[workflowIndex]);
    duplicate.title = `Copy of ${duplicate.title}`;

    let workflowsListClone = cloneDeep(values.workflows);
    workflowsListClone.splice(workflowIndex + 1, 0, duplicate);

    setFieldValue("workflows", workflowsListClone);
    $workflow.select(workflowIndex + 1);
  };

  return (
    <Fragment>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="workflowsList">
          {(provided) => (
            <List disablePadding dense {...provided.droppableProps} ref={provided.innerRef}>
              <ListItemButton dense type="button" onClick={() => setFieldValue("workflows", [workFlowBody(), ...values.workflows])}>
                <ListItemIcon sx={{ pr: 1 }} color="primary.main">
                  <AddCircleOutline fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary={values?.workflows?.length > 1 ? "Prepend New Workflow" : "New Workflow"} primaryTypographyProps={{ fontWeight: "bolder", color: "primary.main" }} />
              </ListItemButton>

              {Children.toArray(
                map(values?.workflows, (workflow, workflowIndex) => (
                  <Draggable key={`workflow-${workflowIndex}`} draggableId={`workflow-${workflowIndex}`} index={workflowIndex}>
                    {(provided) => (
                      <ListItemButton
                        role={undefined}
                        dense
                        type="button"
                        selected={$workflow?.selected === workflowIndex}
                        onClick={() => $workflow.select(workflowIndex)}
                        onContextMenu={(e) => handleContextMenu(e, workflowIndex)}
                        disablePadding
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        id={`workflow-item-${workflowIndex}`}
                      >
                        <ListItemIcon sx={{ pr: 1 }} {...provided.dragHandleProps}>
                          <DragIndicator />
                        </ListItemIcon>
                        <ListItemText
                          primary={workflow?.title}
                          primaryTypographyProps={{
                            fontWeight: $workflow?.selected === workflowIndex ? "bolder" : 500,
                            noWrap: true,
                          }}
                        />
                      </ListItemButton>
                    )}
                  </Draggable>
                ))
              )}
              {provided.placeholder}

              {values?.workflows?.length > 1 && (
                <ListItemButton dense type="button" onClick={() => setFieldValue("workflows", [...values.workflows, workFlowBody()])}>
                  <ListItemIcon sx={{ pr: 1 }} color="primary.main">
                    <AddCircleOutline fontSize="large" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={values?.workflows?.length > 1 ? "Append New Workflow" : "New Workflow"} primaryTypographyProps={{ fontWeight: "bolder", color: "primary.main" }} />
                </ListItemButton>
              )}
            </List>
          )}
        </Droppable>
      </DragDropContext>

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
        MenuListProps={{ disablePadding: true }}
        onClick={handleClose}
      >
        <MenuItem dense divider={<Divider />} onClick={() => $workflow.select(contextMenu.index)}>
          <ListItemIcon>
            <Edit fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: "body2" }} />
        </MenuItem>
        <MenuItem dense divider={<Divider />} onClick={() => handleDuplicateWorkflow(contextMenu.index)}>
          <ListItemIcon>
            <FileCopyOutlined fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Duplicate" primaryTypographyProps={{ variant: "body2" }} />
        </MenuItem>
        <MenuItem dense divider={<Divider />} onClick={() => handleDeleteWorkflow(contextMenu.index)}>
          <ListItemIcon>
            <SvgIcon fontSize="large" inheritViewBox color="error">
              <FiTrash2 />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: "body2", color: "error.main" }} />
        </MenuItem>
      </Menu>
    </Fragment>
  );
}

export default WorkflowList;
