import {
  contractsTemplatesEndpoints,
  getRequest,
  LbLoading,
  lbUid,
  postRequest,
  putRequest,
  useReactQuery,
  useSearchParameters,
} from "@lb/frontend";
import {
  EDITOR_NODE_TYPES,
  generateOptionVariants,
  getNodeFragment,
  getNodeType,
  masterClientUrls,
  OPERATORS,
  RULE_CONJUNCTIONS,
  VARIABLE_TYPES,
  WORKPAPER_CONDITION_ACTIONS,
} from "@lb/utils";
import { useFormik } from "formik";
import {
  compact,
  find,
  findIndex,
  forEach,
  includes,
  isArray,
  isEmpty,
  isObject,
  map,
} from "lodash";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

const STEP_OPTIONS = generateOptionVariants({
  metadata: "Metadata",
  workpaper: "Workpaper",
  manuscript: "Manuscript",
  transcript: "Transcript",
});

function flattenObject(obj, currentPath = "", result = {}) {
  forEach(obj, (value, key) => {
    const newPath = currentPath ? `${currentPath}.${key}` : key;
    if (isObject(value) && !isEmpty(value)) {
      flattenObject(value, newPath, result);
    } else {
      result[newPath] = value;
    }
  });
  return result;
}

const ruleSchema = Yup.object().shape({
  source: Yup.mixed().required("Required"),
  operator: Yup.mixed().required("Required"),
  value: Yup.mixed().required("Required"),
});

const ruleGroupSchema = Yup.array().of(
  Yup.lazy((obj) => {
    if (obj && obj.conjunction) {
      return Yup.object().shape({
        conjunction: Yup.string().oneOf(["and", "or"], "Invalid conjunction").required("Required"),
        rules: Yup.array().of(ruleSchema).required("Required"),
      });
    }
    return ruleSchema;
  })
);

const engine =
  (Component) =>
  ({ ...props }) => {
    const navigate = useNavigate();
    const { templateId, orgId } = useParams();

    const { searchParams, setSearchParams } = useSearchParameters();

    const step = {
      selected: useMemo(
        () =>
          includes(STEP_OPTIONS.keys, searchParams.step) ? searchParams.step : STEP_OPTIONS.keys[0],
        [searchParams.step]
      ),
      select: (v) => setSearchParams({ ...searchParams, step: v }),
    };

    const nextStep = () => {
      const currentIndex = findIndex(STEP_OPTIONS.keys, step.selected);
      step.select(STEP_OPTIONS.keys[currentIndex + 1]);
    };

    const previousStep = () => {
      const currentIndex = findIndex(STEP_OPTIONS.keys, step.selected);
      step.select(STEP_OPTIONS.keys[currentIndex - 1]);
    };

    const handleClose = () => {
      navigate(masterClientUrls.clm.templates.base);
    };

    const {
      data: { data = {} } = {},
      isLoading,
      refetch,
    } = useReactQuery({
      queryKeys: ["templates", templateId, orgId],
      apiCall: () =>
        getRequest({
          url: contractsTemplatesEndpoints.getById(templateId, orgId),
          baseUrl: "clm",
        }),
      options: {
        enabled: Boolean(templateId),
        cacheTime: 0,
      },
    });

    const populateRules = (rulesList) => {
      return map(rulesList, ({ source, value, operator, conjunction, rules }) => {
        let srcVar = find(data.variables, { name: source?.name || source });

        if (isEmpty(rules)) {
          let r = {
            source: srcVar,
            value,
            operator: { label: OPERATORS.all._[operator], value: operator },
          };

          if (srcVar?.type === VARIABLE_TYPES.key.MCQ && srcVar?.multiple)
            r.value = map(value, (v) => ({ label: v, value: v }));

          if (srcVar?.type === VARIABLE_TYPES.key.MCQ && !srcVar?.multiple)
            switch (operator) {
              case OPERATORS.all.key.EQUALS:
                r.value = { label: value, value };
                break;
              case OPERATORS.all.key.NOT_EQUALS:
                r.value = { label: value, value };
                break;
              case OPERATORS.all.key.IN:
                r.value = map(value, (v) => ({ label: v, value: v }));
                break;
              case OPERATORS.all.key.NOT_IN:
                r.value = map(value, (v) => ({ label: v, value: v }));
                break;
              default:
                break;
            }

          return r;
        } else
          return {
            conjunction,
            rules: populateRules(rules),
          };
      });
    };

    const populateNode = (script) => {
      if (isEmpty(script)) return null;

      const nodeId = script.id || lbUid();
      const nodeType = getNodeType(script);

      switch (nodeType) {
        case EDITOR_NODE_TYPES.key.TEXT:
          return {
            ...script,
            id: nodeId,
            text: script.text,
            alignment: script.alignment || "justify",
            bold: script.bold ?? false,
            italics: script.italics ?? false,
            decoration:
              isArray(script.decoration) && !isEmpty(script.decoration) ? script.decoration : [],
            fontSize: script.fontSize || 14,
          };
        case EDITOR_NODE_TYPES.key.PARAGRAPH:
          return {
            ...script,
            id: nodeId,
            text: compact(map(script.text, (i) => populateNode(i))),
            alignment: script.alignment || "justify",
            bold: script.bold ?? false,
            italics: script.italics ?? false,
            decoration:
              isArray(script.decoration) && !isEmpty(script.decoration) ? script.decoration : [],
            fontSize: script.fontSize || 14,
          };
        case EDITOR_NODE_TYPES.key.OL:
          return {
            ...script,
            id: nodeId,
            ol: compact(map(script.ol, (i) => populateNode(i))),
            type: script.type || "decimal",
            bold: script.bold ?? false,
            italics: script.italics ?? false,
            decoration:
              isArray(script.decoration) && !isEmpty(script.decoration) ? script.decoration : [],
            fontSize: script.fontSize || 14,
          };
        case EDITOR_NODE_TYPES.key.UL:
          return {
            ...script,
            id: nodeId,
            ul: compact(map(script.ul, (i) => populateNode(i))),
            type: script.type || "disc",
            bold: script.bold ?? false,
            italics: script.italics ?? false,
            decoration:
              isArray(script.decoration) && !isEmpty(script.decoration) ? script.decoration : [],
            fontSize: script.fontSize || 14,
          };

        case EDITOR_NODE_TYPES.key.SPACE:
          return {
            ...script,
            id: nodeId,
            text: script.text || "\n",
          };
        case EDITOR_NODE_TYPES.key.STACK:
          return {
            ...script,
            id: nodeId,
            stack: compact(map(script.stack, (i) => populateNode(i))),
            alignment: script.alignment || "justify",
            bold: script.bold ?? false,
            italics: script.italics ?? false,
            decoration:
              isArray(script.decoration) && !isEmpty(script.decoration) ? script.decoration : [],
            fontSize: script.fontSize || 14,
          };
        case EDITOR_NODE_TYPES.key.VARIABLE:
          return {
            ...script,
            id: nodeId,
            variable: find(data?.variables, { name: script.variable }),
            alignment: script.alignment || "justify",
            type: script.type || "disc",
            bold: script.bold ?? false,
            italics: script.italics ?? false,
            decoration:
              isArray(script.decoration) && !isEmpty(script.decoration) ? script.decoration : [],
            fontSize: script.fontSize || 14,
          };
        case EDITOR_NODE_TYPES.key.DECISION:
          return {
            ...script,
            id: nodeId,
            rules: populateRules(script.rules),
            then: compact(map(script.then, (i) => populateNode(i))),
            else: compact(map(script.else, (i) => populateNode(i))),
          };
        default:
          break;
      }

      return script;
    };

    const initialValues = useMemo(() => {
      return {
        name: data?.name || "",
        description: data?.description || "",
        content: data?.content || "",
        abbreviation: data?.abbreviation || "",
        variables: map(data?.variables, (variable) => ({
          ...variable,
          label: variable?.label || "",
          description: variable?.description || "",
          selectAll: variable?.selectAll || "",
          name: variable?.name || "",
          multiple: variable?.multiple ?? false,
          options: isArray(variable?.options) ? variable?.options : [],
          value: variable?.value ?? "",
          type: {
            label: VARIABLE_TYPES._[variable?.type],
            value: variable?.type,
          },
          min: variable?.min ?? "",
          max: variable?.max ?? "",
          placeholder: variable?.placeholder || "",
        })),
        workflows:
          map(data.workflows, (workflow) => ({
            conjunction: workflow.conjunction,
            title: workflow.title,
            rules: populateRules(workflow.rules),
            actions: map(workflow.actions, ({ action, payload, target }) => {
              let targetVariable = find(data.variables, { name: target });
              return {
                action: {
                  label: WORKPAPER_CONDITION_ACTIONS._[action],
                  value: action,
                },
                payload:
                  {
                    [WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE]: {
                      [VARIABLE_TYPES.key.MCQ]: targetVariable?.multiple
                        ? compact(
                            map(payload, (v) => ({
                              label: v === "*" ? "Select All (*)" : v,
                              value: v,
                            })) || []
                          )
                        : { label: payload, value: payload },
                    }[targetVariable?.type],
                    [WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS]: compact(
                      map(payload, (v) => ({
                        label: v === "*" ? "Select All (*)" : v,
                        value: v,
                      })) || []
                    ),
                  }[action] || payload,
                target: find(data.variables, { name: target }),
              };
            }),
            altActions: map(workflow.altActions, ({ action, payload, target }) => {
              let targetVariable = find(data.variables, { name: target });
              return {
                action: {
                  label: WORKPAPER_CONDITION_ACTIONS._[action],
                  value: action,
                },
                payload:
                  {
                    [WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE]: {
                      [VARIABLE_TYPES.key.MCQ]: targetVariable?.multiple
                        ? compact(
                            map(payload, (v) => ({
                              label: v === "*" ? "Select All (*)" : v,
                              value: v,
                            })) || []
                          )
                        : { label: payload, value: payload },
                    }[targetVariable?.type],
                    [WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS]: compact(
                      map(payload, (v) => ({
                        label: v === "*" ? "Select All (*)" : v,
                        value: v,
                      })) || []
                    ),
                  }[action] || payload,
                target: find(data.variables, { name: target }),
              };
            }),
          })) || [],
        manuscript: isEmpty(data?.manuscript)
          ? []
          : map(data?.manuscript, (script) => populateNode(script)),
      };
    }, [data]);

    const formik = useFormik({
      validateOnChange: false,
      enableReinitialize: true,
      validationSchema: Yup.object().shape({
        name: Yup.string().required("Name is required"),
        description: Yup.string().notRequired(),
        content: Yup.string().notRequired(),
        abbreviation: Yup.string().notRequired(),
        variables: Yup.array()
          .of(
            Yup.object().shape(
              {
                label: Yup.string().required("Variable label is required"),
                name: Yup.string().required("Variable name is required"),
                type: Yup.mixed().required("Variable type is required"),
                multiple: Yup.boolean().notRequired(),
                options: Yup.array().when("type", {
                  is: (v) => v?.value === VARIABLE_TYPES.key.MCQ,
                  then: Yup.array()
                    .of(Yup.string())
                    .min(1, "Options are required for MCQ type")
                    .required("Options are required for MCQ type"),
                  otherwise: Yup.array().of(Yup.string()).notRequired(),
                }),
                value: Yup.string().when("type", {
                  is: (v) => v?.value === VARIABLE_TYPES.key.STATIC,
                  then: (schema) => schema.required("Variable value is required"),
                  otherwise: (schema) => schema.notRequired(),
                }),
                min: Yup.number().when(
                  ["type", "multiple", "options", "max"],
                  (type, multiple, options, max, schema) => {
                    if (type?.value === VARIABLE_TYPES.key.NUMBER)
                      return schema.max(max, "Min cannot be greater than max").required("Required");
                    if (type?.value === VARIABLE_TYPES.key.MCQ && multiple)
                      return schema
                        .max(
                          max ?? options?.length,
                          "Min cannot be greater than max/ total number of options"
                        )
                        .notRequired();
                    return Yup.mixed().notRequired();
                  }
                ),
                max: Yup.number().when(
                  ["type", "multiple", "options", "min"],
                  (type, multiple, options, min, schema) => {
                    if (type?.value === VARIABLE_TYPES.key.NUMBER)
                      return schema
                        .min(min ?? 0, "Max cannot be smaller than min")
                        .required("Required");
                    if (type?.value === VARIABLE_TYPES.key.MCQ && multiple)
                      return schema
                        .min(min ?? 0, "Max cannot be smaller than min")
                        .max(
                          options?.length,
                          "Max cannot be greater than the total number of options"
                        )
                        .notRequired();
                    return Yup.mixed().notRequired();
                  }
                ),
              },
              ["min", "max"]
            )
          )
          .notRequired(),
        workflows: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string().required("Workflow title is required"),
              conjunction: Yup.string()
                .oneOf(RULE_CONJUNCTIONS.keys, 'Conjunction must be "and" or "or"')
                .required(),
              rules: ruleGroupSchema,
              actions: Yup.array()
                .of(
                  Yup.object().shape({
                    action: Yup.mixed().required("Required"),
                    target: Yup.mixed().required("Required"),
                    payload: Yup.mixed().when(
                      ["action", "target", "$variables"],
                      (action, target, variables, schema) => {
                        if (action?.value === WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS) {
                          return Yup.array()
                            .of(
                              Yup.object().shape({ label: Yup.string(), value: Yup.string() }),
                              "Invalid action payload"
                            )
                            .min(1, "Options required for set-options action")
                            .required("Options required for set-options action");
                        }

                        if (action?.value === WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE) {
                          const targetVar = find(variables, { name: target?.name });

                          if (
                            targetVar?.type?.value === VARIABLE_TYPES.key.MCQ &&
                            targetVar?.multiple
                          ) {
                            return Yup.array()
                              .of(
                                Yup.object().shape({
                                  label: Yup.string().required(),
                                  value: Yup.string().required(),
                                })
                              )
                              .min(1, "Variable value is required for set-value action")
                              .required("Variable value is required for set-value action");
                          }

                          if (
                            targetVar?.type?.value === VARIABLE_TYPES.key.MCQ &&
                            !targetVar?.multiple
                          ) {
                            return Yup.object()
                              .shape({
                                label: Yup.string().required(),
                                value: Yup.string().required(),
                              })
                              .required("Variable value is required for set-value action");
                          }

                          return Yup.string().required(
                            "Variable value is required for set-value action"
                          );
                        }

                        return Yup.mixed().notRequired();
                      }
                    ),
                  })
                )
                .min(1)
                .required("At least one action is required"),
              altActions: Yup.array()
                .of(
                  Yup.object().shape({
                    action: Yup.mixed().required("Required"),
                    target: Yup.mixed().required("Required"),
                    payload: Yup.mixed().when(
                      ["action", "target", "$variables"],
                      (action, target, variables, schema) => {
                        if (action?.value === WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS) {
                          return Yup.array()
                            .of(
                              Yup.object().shape({ label: Yup.string(), value: Yup.string() }),
                              "Invalid action payload"
                            )
                            .min(1, "Options required for set-options action")
                            .required("Options required for set-options action");
                        }

                        if (action?.value === WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE) {
                          const targetVar = find(variables, { name: target?.name });

                          if (
                            targetVar?.type?.value === VARIABLE_TYPES.key.MCQ &&
                            targetVar?.multiple
                          ) {
                            return Yup.array()
                              .of(
                                Yup.object().shape({
                                  label: Yup.string().required(),
                                  value: Yup.string().required(),
                                })
                              )
                              .min(1, "Variable value is required for set-value action")
                              .required("Variable value is required for set-value action");
                          }

                          if (
                            targetVar?.type?.value === VARIABLE_TYPES.key.MCQ &&
                            !targetVar?.multiple
                          ) {
                            return Yup.object()
                              .shape({
                                label: Yup.string().required(),
                                value: Yup.string().required(),
                              })
                              .required("Variable value is required for set-value action");
                          }

                          return Yup.string().required(
                            "Variable value is required for set-value action"
                          );
                        }

                        return Yup.mixed().notRequired();
                      }
                    ),
                  })
                )
                .notRequired(),
            })
          )
          .notRequired(),
      }),
      initialValues,
      onSubmit: async (values, { setSubmitting }) => {
        try {
          await (templateId ? putRequest : postRequest)({
            url: contractsTemplatesEndpoints.update(templateId, orgId),
            data: values,
            baseUrl: "clm",
          });
          await refetch();
        } catch (error) {
        } finally {
          setSubmitting(false);
        }
      },
    });

    const errors = useMemo(() => flattenObject(formik.errors), [formik.errors]);

    if (isLoading || isEmpty(data)) return <LbLoading py={5} />;

    return (
      <Component
        {...props}
        {...{
          handleClose,
          templateId,
          orgId,
          isLoading,
          data,
          previousStep,
          nextStep,
          step,
          STEP_OPTIONS,
          searchParams,
          setSearchParams,
          formik,
          errors,
        }}
      />
    );
  };

export default engine;
