import swal from "sweetalert";
import React, { useMemo } from "react";
import { useFormikContext } from "formik";
import { FiTrash2 } from "react-icons/fi";
import { LbAlert, lbUid } from "@lb/frontend";
import { cloneDeep, filter, findIndex, includes, map } from "lodash";
import { VARIABLE_TYPES } from "@lb/utils";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { setSearchParams, searchParams } = props;
    const { values, errors, setFieldValue } = useFormikContext();

    const variable = {
      selected: useMemo(() => (includes(map(values.variables, "name"), searchParams.variable) ? searchParams.variable : values.variables?.[0]?.name), [values.variables, searchParams.variable]),
      select: (v) => setSearchParams({ ...searchParams, variable: v }),
    };

    function handleDragEnd(result) {
      if (!result.destination) {
        return;
      }
      const newItems = Array.from(values.variables);
      const [removed] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, removed);
      setFieldValue("variables", newItems);
    }

    const removeVariable = (variableName) => {
      LbAlert({
        title: "Warning",
        buttons: ["Cancel", { text: "Confirm", closeModal: false }],
        message: "Confirm to delete?",
        danger: true,
        icon: FiTrash2,
      }).then(async (willDelete) => {
        if (willDelete) {
          setFieldValue(
            "variables",
            filter(values.variables, (variable) => variable.name !== variableName)
          );
        }
        swal.close();
      });
    };

    const addNewVariable = () => {
      setFieldValue("variables", [
        ...values?.variables,
        {
          label: "Untitled",
          description: "",
          name: lbUid(),
          type: {
            label: VARIABLE_TYPES._[VARIABLE_TYPES.key.TEXT],
            value: VARIABLE_TYPES.key.TEXT,
          },
          multiple: false,
          options: [],
          value: "",
          placeholder: "",
        },
      ]);
    };

    const duplicateVariable = (variableName) => {
      const variableIndex = findIndex(values.variables, { name: variableName });
      let duplicate = cloneDeep(values?.variables[variableIndex]);
      duplicate.label = `Copy of ${duplicate.label}`;
      duplicate.name = lbUid();

      let variablesListClone = cloneDeep(values.variables);
      variablesListClone.splice(variableIndex + 1, 0, duplicate);

      setFieldValue("variables", variablesListClone);
      variable.select(duplicate.name);
    };

    return <Component {...props} {...{ handleDragEnd, variable, removeVariable, values, addNewVariable, errors, duplicateVariable }} />;
  };

export default engine;
