import { LbSmartSelect, LbTextField } from "@lb/frontend";
import { VARIABLE_TYPES, WORKPAPER_CONDITION_ACTIONS } from "@lb/utils";
import { DragIndicator } from "@mui/icons-material";
import { Box, Chip, IconButton, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { compact, filter, find, get, includes, map } from "lodash";
import React, { useMemo } from "react";
import { LuGitCommit } from "react-icons/lu";

function ActionFields({ provided, name, removeAction }) {
  const { values, setFieldValue } = useFormikContext();
  const targetVar = useMemo(
    () =>
      find(values.variables, {
        name: get(values, `${name}.target.name`) || get(values, `${name}.target`) || {},
      }),
    [name, values]
  );
  const actionValue = useMemo(() => get(values, `${name}.action.value`), [name, values]);

  const variablesList = useMemo(
    () =>
      map(
        filter(values.variables, (v) => {
          if (actionValue === WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS)
            return v.type?.value === VARIABLE_TYPES.key.MCQ;
          return true;
        }),
        (variable) => ({
          label: variable?.label,
          name: variable?.name,
        })
      ),
    [actionValue, values.variables]
  );

  return (
    <Stack
      direction="row"
      spacing={2}
      ref={provided.innerRef}
      {...provided.draggableProps}
      sx={{
        position: "relative",
        "&::before": {
          content: '""',
          width: "100%",
          position: "absolute",
          borderTop: `2px dashed`,
          borderColor: "secondary.light",
          top: "20px",
          left: 0,
          zIndex: -1,
        },
      }}
    >
      <Box pt={0.5}>
        <IconButton
          {...provided.dragHandleProps}
          size="small"
          color="secondary"
          sx={{
            cursor: "grab",
            border: 1.5,
            backgroundColor: "white!important",
            height: "fit-content",
          }}
        >
          <DragIndicator fontSize="small" />
        </IconButton>
      </Box>
      <LbSmartSelect
        required
        name={`${name}.action`}
        label="Action"
        placeholder="Select action you want to perform"
        options={WORKPAPER_CONDITION_ACTIONS.labelValue}
        callback={(selectedAction) => {
          setFieldValue(`${name}.payload`, "");

          if (selectedAction?.value === WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS) {
            if (targetVar?.type?.value === VARIABLE_TYPES.key.MCQ) {
              setFieldValue(`${name}.payload`, []);
            } else {
              setFieldValue(`${name}.payload`, "");
              setFieldValue(`${name}.target`, "");
            }
          }

          switch (selectedAction?.value) {
            case WORKPAPER_CONDITION_ACTIONS.key.SHOW:
            case WORKPAPER_CONDITION_ACTIONS.key.HIDE:
              setFieldValue(`${name}.payload`, "");
              break;
            case WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS:
              if (targetVar?.type?.value === VARIABLE_TYPES.key.MCQ) {
                setFieldValue(`${name}.payload`, []);
              } else {
                setFieldValue(`${name}.payload`, "");
                setFieldValue(`${name}.target`, "");
              }
              break;
            case WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE:
              if (targetVar?.type?.value === VARIABLE_TYPES.key.MCQ) {
                setFieldValue(`${name}.payload`, []);
              } else {
                setFieldValue(`${name}.payload`, "");
              }
              break;
            default:
              break;
          }
        }}
      />

      <LbSmartSelect
        name={`${name}.target`}
        required
        label="Variable"
        placeholder="Select the variable you want to perform action upon"
        options={variablesList}
        valueKey="name"
        callback={(selectedVariable) => {
          setFieldValue(`${name}.payload`, "");
        }}
      />

      {includes(
        [WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS, WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE],
        actionValue
      ) &&
        {
          [WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS]: (
            <LbSmartSelect
              name={`${name}.payload`}
              required
              label="Variable value"
              placeholder="Select the variable values"
              options={compact(
                map(targetVar?.options, (option) => ({
                  label: option,
                  value: option,
                }))
              )}
              multiple={true}
              selectAll={targetVar.selectAll}
            />
          ),
          [WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE]:
            targetVar?.type?.value === VARIABLE_TYPES.key.MCQ ? (
              <LbSmartSelect
                name={`${name}.payload`}
                required
                label="Variable value"
                placeholder="Select the variable value"
                options={compact(
                  map(targetVar?.options, (option) => ({
                    label: option,
                    value: option,
                  }))
                )}
                multiple={targetVar.multiple}
                selectAll={targetVar.selectAll}
              />
            ) : (
              <LbTextField
                name={`${name}.payload`}
                required
                label="Variable value"
                placeholder="Enter the variable value"
                boxProps={{
                  sx: {
                    backgroundColor: "white",
                  },
                }}
              />
            ),
        }[actionValue]}

      <Box pt={1}>
        <Chip
          label="Remove"
          icon={<LuGitCommit />}
          size="small"
          variant="outlined"
          color={removeAction ? "error" : "default"}
          sx={{
            borderWidth: 1.5,
            backgroundColor: "white",
            "&:hover": { backgroundColor: "white!important" },
            cursor: removeAction ? "pointer" : "not-allowed",
          }}
          onClick={removeAction}
        />
      </Box>
    </Stack>
  );
}

export default ActionFields;
