import { useMiscStore } from "@lb/frontend";
import { VARIABLE_TYPES, WORKPAPER_CONDITION_ACTIONS, depopulateScripts, depopulateRules } from "@lb/utils";
import { useFormik, useFormikContext } from "formik";
import { compact, find, fromPairs, map } from "lodash";
import React, { useMemo } from "react";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { values } = useFormikContext();
    const { misc, storeMisc } = useMiscStore();
    const { data } = props;

    const MISC_ID = `${data._id}-contract-workpaper`;

    const variables = useMemo(() => {
      return map(values.variables, (variable) => ({
        ...variable,
        type: variable?.type?.value,
      }));
    }, [values.variables]);

    const workflows = useMemo(() => {
      return map(values.workflows, (workflow) => ({
        actions: map(workflow.actions, ({ action, payload, target }) => {
          let selectAction = action?.value || action,
            targetVariable = find(variables, {
              name: target?.name || target,
            });
          return {
            action: selectAction,
            payload:
              {
                [WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE]:
                  {
                    [VARIABLE_TYPES.key.MCQ]: targetVariable?.multiple ? compact(map(payload, "value") || []) : payload.value || payload,
                  }[targetVariable?.type] || payload,
                [WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS]: compact(map(payload, "value")),
              }[selectAction] || payload,
            target: targetVariable?.name,
          };
        }),
        altActions: map(workflow.altActions, ({ action, payload, target }) => {
          let selectAction = action?.value || action,
            targetVariable = find(variables, {
              name: target?.name,
            });
          return {
            action: selectAction,
            payload:
              {
                [WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE]:
                  {
                    [VARIABLE_TYPES.key.MCQ]: targetVariable?.multiple ? compact(map(payload, "value")) || [] : payload.value || payload,
                  }[targetVariable?.type] || payload,
                [WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS]: compact(map(payload, "value")),
              }[selectAction] || payload,
            target: targetVariable?.name,
          };
        }),
        conjunction: workflow.conjunction,
        title: workflow.title,
        rules: depopulateRules(workflow.rules, variables),
      }));
    }, [values.workflows, variables]);

    const manuscript = useMemo(() => compact(map(values.manuscript, (s) => depopulateScripts(s))), [values.manuscript]);

    const initialWorkpaperValues = useMemo(
      () =>
        fromPairs(
          map(variables, (v) => {
            if (v.type === VARIABLE_TYPES.key.STATIC) {
              return [v.name, v.value];
            }

            return [v.name, misc[MISC_ID]?.[v.name]];
          })
        ),
      [MISC_ID, misc, variables]
    );

    const contractFormik = useFormik({
      validateOnChange: false,
      initialValues: {
        workpaper: initialWorkpaperValues,
      },
      onSubmit: (values, { setSubmitting }) => {
        storeMisc({ [MISC_ID]: values.workpaper });
        setSubmitting(false);
      },
      enableReinitialize: true,
    });

    return <Component {...props} {...{ contractFormik, workflows, variables, manuscript, values }} />;
  };

export default engine;
