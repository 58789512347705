import { find } from "lodash";
import swal from "sweetalert";
import { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { CellTowerOutlined, Construction, Error, Person, Warning } from "@mui/icons-material";
import { getRequest, useReactQuery, orgEndpoints, LbAlert, deleteRequest, patchRequest } from "@lb/frontend";

const engine =
  (Component) =>
  ({ ...props }) => {
    const [searchVal, setSearchVal] = useState();
    const [pagination, setPagination] = useState({ page: 1, limit: 10 });

    const {
      data: { data, total } = {},
      isLoading,
      refetch,
    } = useReactQuery({
      queryKeys: ["organizations", pagination.page, pagination.limit, searchVal],
      apiCall: () =>
        getRequest({
          url: orgEndpoints.getAll,
          params: {
            pageNo: pagination.page,
            limit: pagination.limit,
            search: searchVal,
          },
          baseUrl: "master",
        }),
    });

    const deactivate = (organizationId) => {
      LbAlert({
        title: "Warning",
        buttons: ["Cancel", { text: "Confirm", closeModal: false }],
        message: "Are you sure you want to delete this organization?",
        danger: true,
        icon: FiTrash2,
      }).then(async (willDelete) => {
        if (willDelete) {
          await deleteRequest({
            url: orgEndpoints.delete(organizationId),
            baseUrl: "master",
          })
            .then(() => refetch())
            .finally(() => swal.close());
        }
      });
    };

    const reinvite = (id) => {
      LbAlert({
        title: "Warning",
        buttons: ["Cancel", { text: "Confirm", closeModal: false }],
        message: "Are you sure you want to resend the invitation?",
        danger: true,
        icon: Person,
      }).then(async (willDelete) => {
        if (willDelete) {
          await patchRequest({
            url: orgEndpoints.reinvite(id),
            baseUrl: "master",
          }).finally(() => swal.close());
        }
      });
    };

    const enableMaintenanceMode = async (_id) => {
      const orgName = find(data, { _id })?.name;
      LbAlert({
        title: "Warning",
        buttons: ["Cancel", { text: "Confirm", closeModal: false }],
        message: `Are you sure you want to put this organization ${orgName} to maintenance mode? Organization users/vendors will not be able to login. Routine operations will be paused for this organization.`,
        danger: true,
      }).then(async (reply) => {
        if (reply) {
          try {
            await patchRequest({
              url: orgEndpoints.enableMaintenance(_id),
              baseUrl: "master",
            });
            refetch();
          } catch (error) {
          } finally {
            swal.close();
          }
        }
      });
    };

    const disableMaintenanceMode = async (_id) => {
      const orgName = find(data, { _id })?.name;
      LbAlert({
        title: "Warning",
        buttons: ["Cancel", { text: "Confirm", closeModal: false }],
        message: `Are you sure you want to disable maintenance mode this organization ${orgName} to ? Organization users/vendors will be able to login. Routine operations will be continued for this organization.`,
        danger: true,
      }).then(async (reply) => {
        if (reply) {
          try {
            await patchRequest({
              url: orgEndpoints.disableMaintenance(_id),
              baseUrl: "master",
            });
            refetch();
          } catch (error) {
          } finally {
            swal.close();
          }
        }
      });
    };

    return (
      <Component
        {...props}
        {...{
          data,
          isLoading,
          setSearchVal,
          pagination: {
            ...pagination,
            total,
          },
          setPagination,
          deactivate,
          reinvite,
          enableMaintenanceMode,
          disableMaintenanceMode,
        }}
      />
    );
  };

export default engine;
