import { getThinScrollBar, LbButton } from "@lb/frontend";
import { lbBlue, VARIABLE_TYPES } from "@lb/utils";
import { DragHandle, DragIndicator, FileCopyOutlined } from "@mui/icons-material";
import { Box, Icon, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { isEmpty, map, snakeCase, upperCase } from "lodash";
import React, { Children } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FiCopy, FiTrash2 } from "react-icons/fi";
import { GoTools } from "react-icons/go";
import { HiOutlineDuplicate } from "react-icons/hi";
import EditVariable from "./Edit";
import engine from "./engine";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineContentCopy } from "react-icons/md";

function Variables({ handleDragEnd, variable, removeVariable, values, errors, addNewVariable, duplicateVariable }) {
  return (
    <Box pt={2}>
      <LbButton onClick={addNewVariable}>New Variable</LbButton>
      <Grid container spacing={1} mt={2}>
        <Grid size={{ xs: variable.selected ? 6 : 12 }} sx={{ pr: 1, pb: 2, pt: 0.5 }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="list">
              {(provided, snapshot) => {
                return (
                  <Grid container spacing={2} ref={provided.innerRef} {...provided.droppableProps}>
                    {Children.toArray(
                      map(values?.variables, (item, index) => {
                        return (
                          <Draggable
                            {...{
                              index,
                              key: item.name,
                              id: item.name,
                              draggableId: item.name,
                            }}
                          >
                            {(provided, snapshot) => {
                              return (
                                <Grid size={{ xs: 12 }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <Paper
                                    sx={{
                                      boxSizing: "border-box",
                                      py: 1,
                                      px: 2,
                                      border: 2,
                                      borderColor: snapshot.isDragging || variable.selected === item?.name ? (!isEmpty(errors?.variables?.[index]) ? "error.main" : "primary.main") : "#fff",
                                      borderRadius: "8px",
                                      backgroundColor: snapshot.isDragging ? lbBlue.shade14 : "#fff",
                                      "&:hover": {
                                        backgroundColor: lbBlue.shade15,
                                      },
                                    }}
                                    onClick={() => variable.select(item.name)}
                                  >
                                    <Stack direction="row" spacing={2} alignItems={"center"}>
                                      <Typography variant="h4" color={!isEmpty(errors?.variables?.[index]) ? "error.main" : "grey.300"}>
                                        #{index + 1}
                                      </Typography>
                                      <Box flex={1}>
                                        <Typography variant="body2" fontWeight="bolder">
                                          {item.label}
                                        </Typography>
                                        <Stack direction="row" alignItems="center" justifyContent={"space-between"} flexWrap="wrap">
                                          <Typography variant="caption" color="text.secondary">
                                            #{item.name}
                                          </Typography>
                                          <img
                                            alt="Static Badge"
                                            src={`https://img.shields.io/badge/${upperCase(snakeCase(item.type?.label))}-${upperCase(
                                              snakeCase(
                                                {
                                                  [VARIABLE_TYPES.key.TEXT]: "Text Box",
                                                  [VARIABLE_TYPES.key.MCQ]: item.multiple ? "Multi Select" : "Single Select",
                                                  [VARIABLE_TYPES.key.NUMBER]: "Numeric Input",
                                                  [VARIABLE_TYPES.key.STATIC]: "Just Text",
                                                }[item.type?.value]
                                              )
                                            )}-${
                                              {
                                                [VARIABLE_TYPES.key.TEXT]: "blue", // Blue
                                                [VARIABLE_TYPES.key.MCQ]: item.multiple ? "red" : "orange", // Purple for checkbox, red for radio
                                                [VARIABLE_TYPES.key.NUMBER]: "teal", // Teal for number input
                                                [VARIABLE_TYPES.key.STATIC]: "darkviolet", // Gray for static text
                                              }[item.type?.value]
                                            }?style=flat&labelColor=black`}
                                          />
                                        </Stack>
                                      </Box>

                                      <Stack direction="row" alignItems="center">
                                        <Tooltip title="Delete" arrow>
                                          <IconButton
                                            color="error"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              removeVariable(item.name);
                                            }}
                                          >
                                            <Icon fontSize="small">
                                              <FiTrash2 />
                                            </Icon>
                                          </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Duplicate" arrow>
                                          <IconButton
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              duplicateVariable(item.name);
                                            }}
                                          >
                                            <FileCopyOutlined fontSize="small" />
                                          </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Config" arrow>
                                          <IconButton onClick={() => variable.select(item.name)}>
                                            <Icon fontSize="small">
                                              <GoTools />
                                            </Icon>
                                          </IconButton>
                                        </Tooltip>

                                        {snapshot.isDragging ? (
                                          <DragHandle
                                            fontSize="small"
                                            sx={{
                                              cursor: "grab",
                                              color: "grey.500",
                                            }}
                                          />
                                        ) : (
                                          <DragIndicator
                                            fontSize="small"
                                            sx={{
                                              cursor: "grab",
                                              color: "grey.500",
                                            }}
                                          />
                                        )}
                                      </Stack>
                                    </Stack>
                                  </Paper>
                                </Grid>
                              );
                            }}
                          </Draggable>
                        );
                      })
                    )}
                    {provided.placeholder}
                  </Grid>
                );
              }}
            </Droppable>
          </DragDropContext>
        </Grid>

        {variable.selected && (
          <Grid
            size={{ xs: 6 }}
            sx={{
              overflowY: "auto",
              height: "100%",
              maxHeight: "85vh",
              ...getThinScrollBar(),
              pr: 1,
              position: "sticky",
              top: 50,
            }}
          >
            <EditVariable {...{ variable }} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default engine(Variables);
