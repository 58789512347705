import { LbNumberField, LbSmartSelect, LbTextField } from "@lb/frontend";
import { depopulateVariables, OPERATORS, VARIABLE_TYPES } from "@lb/utils";
import { Box, Chip, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { find, get, has, includes, isArray, isEmpty, map, pick, upperCase } from "lodash";
import React, { Fragment, useMemo } from "react";
import { LuGitCommit } from "react-icons/lu";

function RuleFields({ name, removeRule, connectorColor }) {
  const { values, setFieldValue } = useFormikContext();

  const variablesList = useMemo(
    () => depopulateVariables(values.variables) || [],
    [values.variables]
  );

  const VARIABLE_LIST_OPTIONS = useMemo(
    () => map(variablesList, (v) => pick(v, ["label", "name"])) || [],
    [variablesList]
  );

  const selectedSourceVar = useMemo(
    () => find(variablesList, { name: get(values, `${name}.source.name`) }) || {},
    [name, values, variablesList]
  );
  console.log("🚀 ~ RuleFields ~ selectedSourceVar:", selectedSourceVar)

  const variableResponseOptions = useMemo(
    () => map(selectedSourceVar?.options, (o) => ({ label: o, value: o })),
    [selectedSourceVar?.options]
  );

  const operatorOptions = useMemo(() => {
    return (
      {
        [VARIABLE_TYPES.key.MCQ]: selectedSourceVar?.multiple
          ? OPERATORS.mmcq.labelValue
          : OPERATORS.mcq.labelValue,
        [VARIABLE_TYPES.key.TEXT]: OPERATORS.text.labelValue,
        [VARIABLE_TYPES.key.STATIC]: OPERATORS.text.labelValue,
        [VARIABLE_TYPES.key.NUMBER]: OPERATORS.number.labelValue,
      }[selectedSourceVar?.type?.value || selectedSourceVar?.type] || OPERATORS.all.labelValue
    );
  }, [selectedSourceVar]);

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        position: "relative",
        "&::before": {
          content: '""',
          width: "100%",
          position: "absolute",
          borderTopWidth: 2,
          borderTopStyle: "dashed",
          borderTopColor: connectorColor,
          top: "20px",
          left: -30,
          zIndex: -1,
        },
      }}
    >
      <LbSmartSelect
        placeholder="Select variable"
        required
        name={`${name}.source`}
        label="Variable"
        options={VARIABLE_LIST_OPTIONS}
        valueKey="name"
        callback={(selectedVariable) => {
          setFieldValue(`${name}.value`, "");

          const sv = find(values.variables, { name: selectedVariable?.name });
          const so = get(values, `${name}.operator`)?.value;

          if (isEmpty(!sv)) {
            if (sv?.type === VARIABLE_TYPES.key.MCQ && sv?.multiple) {
              if (!includes(OPERATORS.mmcq.keys, so)) setFieldValue(`${name}.operator`, "");
            }
            if (sv?.type === VARIABLE_TYPES.key.MCQ && !sv?.multiple) {
              if (!includes(OPERATORS.mcq.keys, so)) setFieldValue(`${name}.operator`, "");
            }
          }

          if (sv?.type === VARIABLE_TYPES.key.NUMBER) {
            if (!includes(OPERATORS.number.keys, so)) setFieldValue(`${name}.operator`, "");
          }

          if (sv?.type === VARIABLE_TYPES.key.TEXT || sv?.type === VARIABLE_TYPES.key.STATIC) {
            if (!includes(OPERATORS.text.keys, so)) setFieldValue(`${name}.operator`, "");
          }
        }}
      />

      <LbSmartSelect
        required
        placeholder="Select operator"
        name={`${name}.operator`}
        label={`${upperCase(selectedSourceVar?.type?.value || selectedSourceVar?.type)} Operator`}
        disabled={isEmpty(get(values, `${name}.source`))}
        options={operatorOptions}
        callback={(selected) => {
          if (isEmpty(!selectedSourceVar)) {
            const variableValue = get(values, `${name}.value`);

            if (selectedSourceVar?.type === VARIABLE_TYPES.key.MCQ && selectedSourceVar?.multiple)
              if (!has(variableValue, [0, "label"])) setFieldValue(`${name}.value`, "");

            if (
              selectedSourceVar?.type === VARIABLE_TYPES.key.MCQ &&
              !selectedSourceVar?.multiple
            ) {
              switch (selected?.value) {
                case OPERATORS.all.key.EQUALS:
                  if (!has(variableValue, ["label"])) setFieldValue(`${name}.value`, "");
                  break;
                case OPERATORS.all.key.NOT_EQUALS:
                  if (!has(variableValue, ["label"])) setFieldValue(`${name}.value`, "");
                  break;
                case OPERATORS.all.key.IN:
                  if (!has(variableValue, [0, "label"])) setFieldValue(`${name}.value`, "");
                  break;
                case OPERATORS.all.key.NOT_IN:
                  if (!has(variableValue, [0, "label"])) setFieldValue(`${name}.value`, "");
                  break;
                default:
                  break;
              }
            }

            if (selectedSourceVar?.type === VARIABLE_TYPES.key.NUMBER) {
              if (
                includes(
                  [OPERATORS.all.key.NOT_BETWEEN, OPERATORS.all.key.BETWEEN],
                  selected?.value
                )
              ) {
                if (!isArray(variableValue)) {
                  setFieldValue(`${name}.value`, ["", ""]);
                }
              }
            }
          }
        }}
      />

      {{
        [VARIABLE_TYPES.key.MCQ]: (
          <LbSmartSelect
            placeholder="Select value"
            required
            name={`${name}.value`}
            options={variableResponseOptions}
            disabled={
              isEmpty(get(values, `${name}.operator`)) || isEmpty(get(values, `${name}.source`))
            }
            label="Variable value"
            multiple={
              includes(
                [
                  OPERATORS.all.key.IN,
                  OPERATORS.all.key.NOT_IN,
                  OPERATORS.all.key.CONTAINS,
                  OPERATORS.all.key.DOES_NOT_CONTAINS,
                ],
                get(values, `${name}.operator.value`)
              ) || selectedSourceVar?.multiple
            }
            selectAll={selectedSourceVar.selectAll}
          />
        ),
        [VARIABLE_TYPES.key.NUMBER]: !includes(
          [OPERATORS.number.key.BETWEEN, OPERATORS.number.key.NOT_BETWEEN],
          get(values, `${name}.operator.value`)
        ) ? (
          <LbNumberField
            placeholder="00"
            required
            name={`${name}.value`}
            disabled={
              isEmpty(get(values, `${name}.operator`)) || isEmpty(get(values, `${name}.source`))
            }
            label="Variable value"
            fullWidth
            sx={{ backgroundColor: "white" }}
          />
        ) : (
          <Fragment>
            <LbNumberField
              placeholder="00"
              required
              name={`${name}.value.0`}
              disabled={
                isEmpty(get(values, `${name}.operator`)) || isEmpty(get(values, `${name}.source`))
              }
              label="Variable low"
              fullWidth
              sx={{ backgroundColor: "white" }}
            />
            <LbNumberField
              placeholder="00"
              required
              name={`${name}.value.1`}
              disabled={
                isEmpty(get(values, `${name}.operator`)) || isEmpty(get(values, `${name}.source`))
              }
              label="Variable high"
              fullWidth
              sx={{ backgroundColor: "white" }}
            />
          </Fragment>
        ),
      }[selectedSourceVar?.type] || (
        <LbTextField
          required
          name={`${name}.value`}
          label="Variable value"
          placeholder="Enter the variable value"
          boxProps={{ backgroundColor: "white" }}
          disabled={
            isEmpty(get(values, `${name}.operator`)) || isEmpty(get(values, `${name}.source`))
          }
        />
      )}

      <Box pt={1}>
        <Chip
          label={"Remove"}
          icon={<LuGitCommit />}
          size="small"
          variant="outlined"
          color={removeRule ? "error" : "default"}
          sx={{
            borderWidth: 1.5,
            backgroundColor: "white",
            "&:hover": { backgroundColor: "white!important" },
            cursor: removeRule ? "pointer" : "not-allowed",
          }}
          onClick={removeRule}
        />
      </Box>
    </Stack>
  );
}

export default RuleFields;
