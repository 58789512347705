import { Box, Chip, Stack, Typography } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import { get, map, size } from "lodash";
import React, { Children, Fragment, memo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { LuGitCommit } from "react-icons/lu";
import ActionFields from "./ActionFields";

function AltActions({ name }) {
  const { values, setFieldValue } = useFormikContext();

  return (
    <FieldArray name={`${name}.altActions`}>
      {({ push: pushAction, remove: removeAction, swap }) => (
        <Fragment>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              position: "relative",
              "&::before": {
                content: '""',
                width: "100%",
                position: "absolute",
                borderTop: `2px dashed`,
                borderColor: "secondary.light",
                top: "50%",
                left: 0,
                transform: "translateY(-50%)",
                zIndex: -1,
              },
            }}
            spacing={3}
          >
            <Box flex={1}>
              <Typography
                variant="h5"
                fontWeight="bolder"
                fontFamily="courier"
                bgcolor="white"
                width={"fit-content"}
              >
                ELSE
              </Typography>
            </Box>
            <Chip
              label="Add Action"
              icon={<LuGitCommit />}
              size="small"
              variant="outlined"
              color="secondary"
              sx={{
                borderWidth: 1.5,
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white!important" },
              }}
              onClick={() => pushAction({ action: "", target: "", payload: "" })}
            />
            {get(values, `${name}.altActions`)?.length > 0 && (
              <Chip
                label="Remove"
                icon={<LuGitCommit />}
                size="small"
                variant="outlined"
                color="error"
                sx={{
                  borderWidth: 1.5,
                  backgroundColor: "white",
                  "&:hover": { backgroundColor: "white!important" },
                }}
                onClick={() => setFieldValue(`${name}.altActions`, [])}
              />
            )}
          </Stack>
          <DragDropContext
            onDragEnd={(result) => swap(result.source.index, result.destination.index)}
          >
            <Droppable droppableId="altactions">
              {(provided) => (
                <Stack {...provided.droppableProps} ref={provided.innerRef} spacing={3}>
                  {Children.toArray(
                    map(get(values, `${name}.altActions`), (actionInstance, actionIndex) => {
                      return (
                        <Draggable
                          key={actionIndex}
                          draggableId={`action-${actionIndex}`}
                          index={actionIndex}
                        >
                          {(provided) => (
                            <ActionFields
                              name={`${name}.altActions.${actionIndex}`}
                              provided={provided}
                              removeAction={
                                size(get(values, `${name}.altActions`)) > 1
                                  ? () => removeAction(actionIndex)
                                  : null
                              }
                            />
                          )}
                        </Draggable>
                      );
                    })
                  )}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </Fragment>
      )}
    </FieldArray>
  );
}

export default memo(AltActions);
