import { LbTabs } from "@lb/frontend";
import React, { createElement, Fragment } from "react";
import engine from "./engine";
import Test from "./Test";
import Variables from "./Variables";
import Workflows from "./Workflows";
import { Toolbar } from "@mui/material";

function Workpaper({ tab, TAB_OPTIONS, previousStep, nextStep, searchParams, setSearchParams }) {
  return (
    <Fragment>
      <LbTabs activeTab={tab.selected} handleTabChange={tab.select} tabs={TAB_OPTIONS.labelValue} />
      {createElement(
        {
          [TAB_OPTIONS.key.VARIABLES]: Variables,
          [TAB_OPTIONS.key.WORKFLOWS]: Workflows,
          [TAB_OPTIONS.key.TEST]: Test,
        }[tab.selected] || Variables,
        { tab, TAB_OPTIONS, previousStep, nextStep, searchParams, setSearchParams }
      )}

      <Toolbar />
    </Fragment>
  );
}

export default engine(Workpaper);