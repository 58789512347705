import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatOverlineRoundedIcon from "@mui/icons-material/FormatOverlineRounded";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import StrikethroughSRoundedIcon from "@mui/icons-material/StrikethroughSRounded";
import { ClickAwayListener, Stack, SvgIcon } from "@mui/material";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup, { toggleButtonGroupClasses } from "@mui/material/ToggleButtonGroup";
import { get, includes, map, without } from "lodash";
import * as React from "react";

import { EDITOR_NODE_TYPES, LIST_STYLE_TYPE } from "@lb/utils";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { useFormikContext } from "formik";
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
    marginLeft: -1,
    borderLeft: "1px solid transparent",
  },
}));

export default function Attributes({ setConfigAnchorEl, configAnchorEl }) {
  const { values, setFieldValue } = useFormikContext();

  const nodeName = configAnchorEl.getAttribute("data-node-name");
  const nodeType = configAnchorEl.getAttribute("data-node-type");

  const nodeData = React.useMemo(() => {
    return get(values, `${nodeName}`);
  }, [nodeName, values]);

  const handleFormat = (event, newFormats) => {
    if (includes(newFormats, "bold")) setFieldValue(`${nodeName}.bold`, true);
    else setFieldValue(`${nodeName}.bold`, false);

    if (includes(newFormats, "italics")) setFieldValue(`${nodeName}.italics`, true);
    else setFieldValue(`${nodeName}.italics`, false);

    setFieldValue(`${nodeName}.decoration`, without(newFormats, "bold", "italics"));
  };

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment) setFieldValue(`${nodeName}.alignment`, newAlignment);
  };

  const handleListType = (event, newAListStyleType) => {
    if (newAListStyleType) setFieldValue(`${nodeName}.type`, newAListStyleType);
  };

  const handleConjunction = (event, newConjunction) => {
    if (newConjunction) setFieldValue(`${nodeName}.conjunction`, newConjunction);
  };

  return (
    <ClickAwayListener onClickAway={() => setConfigAnchorEl(null)} disableReactTree>
      <Stack
        direction={"row"}
        spacing={1}
        component={Paper}
        elevation={4}
        sx={(theme) => ({
          border: `1px solid ${theme.palette.divider}`,
          position: "absolute",
          right: 0,
          zIndex: 1,
          top: 20,
          "& .Mui-selected": {
            color: "primary.dark",
          },
        })}
        divider={<Divider flexItem variant="inset" orientation="vertical" />}
      >
        {includes([EDITOR_NODE_TYPES.key.RULES], nodeType) && (
          <StyledToggleButtonGroup size="small" value={nodeData.conjunction} exclusive onChange={handleConjunction} aria-label="rule conjunction">
            <ToggleButton value="and" aria-label="and rule">
              and
            </ToggleButton>
            <ToggleButton value="or" aria-label="or rule">
              &nbsp;or&nbsp;
            </ToggleButton>
          </StyledToggleButtonGroup>
        )}

        {includes([EDITOR_NODE_TYPES.key.TEXT, EDITOR_NODE_TYPES.key.VARIABLE], nodeType) && (
          <StyledToggleButtonGroup size="small" value={nodeData.alignment} exclusive onChange={handleAlignment} aria-label="text alignment">
            <ToggleButton value="left" aria-label="left aligned">
              <FormatAlignLeftIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="center" aria-label="centered">
              <FormatAlignCenterIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="right" aria-label="right aligned">
              <FormatAlignRightIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="justify" aria-label="justified">
              <FormatAlignJustifyIcon fontSize="small" />
            </ToggleButton>
          </StyledToggleButtonGroup>
        )}

        {includes([EDITOR_NODE_TYPES.key.OL], nodeType) && (
          <StyledToggleButtonGroup size="small" value={nodeData.type} exclusive onChange={handleListType} aria-label="ordered list style type">
            {React.Children.toArray(
              map(LIST_STYLE_TYPE.ol.keys, (listStyleType) => {
                return (
                  <ToggleButton value={listStyleType} aria-label={`${listStyleType} list style type`}>
                    <SvgIcon fontSize="small" inheritViewBox>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <text
                          x="50%"
                          y="55%"
                          fontSize="18px"
                          fontWeight={"bolder"}
                          style={{ lineHeight: 0, textTransform: "none" }}
                          textAnchor="middle"
                          fill="currentColor"
                          dominantBaseline="middle"
                          fontFamily="courier"
                        >
                          {
                            {
                              [LIST_STYLE_TYPE.ol.key.DECIMAL]: "1",
                              [LIST_STYLE_TYPE.ol.key.UPPER_ALPHA]: "A",
                              [LIST_STYLE_TYPE.ol.key.LOWER_ALPHA]: "a",
                              [LIST_STYLE_TYPE.ol.key.UPPER_ROMAN]: "I",
                              [LIST_STYLE_TYPE.ol.key.LOWER_ROMAN]: "i",
                              [LIST_STYLE_TYPE.ol.key.NONE]: "-",
                            }[listStyleType]
                          }
                        </text>
                      </svg>
                    </SvgIcon>
                  </ToggleButton>
                );
              })
            )}
          </StyledToggleButtonGroup>
        )}

        {includes([EDITOR_NODE_TYPES.key.UL], nodeType) && (
          <StyledToggleButtonGroup size="small" value={nodeData.type} exclusive onChange={handleListType} aria-label="unordered list style type">
            {React.Children.toArray(
              map(LIST_STYLE_TYPE.ul.keys, (listStyleType) => {
                return (
                  <ToggleButton value={listStyleType} aria-label={`${listStyleType} list style type`}>
                    {
                      {
                        [LIST_STYLE_TYPE.all.key.DISC]: <FiberManualRecordIcon fontSize="small" />,
                        [LIST_STYLE_TYPE.all.key.CIRCLE]: <FiberManualRecordOutlinedIcon fontSize="small" />,
                        [LIST_STYLE_TYPE.all.key.SQUARE]: <StopRoundedIcon fontSize="small" />,
                        [LIST_STYLE_TYPE.all.key.NONE]: <RemoveOutlinedIcon fontSize="small" />,
                      }[listStyleType]
                    }
                  </ToggleButton>
                );
              })
            )}
          </StyledToggleButtonGroup>
        )}

        {includes([EDITOR_NODE_TYPES.key.TEXT, EDITOR_NODE_TYPES.key.VARIABLE], nodeType) && (
          <StyledToggleButtonGroup
            size="small"
            value={[...nodeData?.decoration, ...(nodeData.bold ? ["bold"] : []), ...(nodeData.italics ? ["italics"] : [])]}
            onChange={handleFormat}
            aria-label="text formatting"
          >
            <ToggleButton value="bold" aria-label="bold">
              <FormatBoldIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="italics" aria-label="italics">
              <FormatItalicIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="underline" aria-label="underline">
              <FormatUnderlinedIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="line-through" aria-label="line through">
              <StrikethroughSRoundedIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="overline" aria-label="overline">
              <FormatOverlineRoundedIcon fontSize="small" />
            </ToggleButton>
          </StyledToggleButtonGroup>
        )}
      </Stack>
    </ClickAwayListener>
  );
}

// <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
// <IconButton value="color" aria-label="color">
//   <FormatColorFillIcon fontSize="small" />
//   <ArrowDropDownIcon fontSize="small" />
//   <input type="color" list="contract-text-color-list" placeholder="Select text color" style={{ position: "absolute", height: "100%" }} />
//   <datalist id="contract-text-color-list">
//     <option selected value="#000000">
//       Reset to Black
//     </option>{" "}
//     {/* Reset option */}
//     {React.Children.toArray(map(backgroundColors, (color) => <option value={color}>{color}</option>))}
//   </datalist>
// </IconButton>
// <IconButton value="color" aria-label="color">
//   <FormatColorTextRoundedIcon fontSize="small" />
//   <ArrowDropDownIcon fontSize="small" />
// </IconButton>
