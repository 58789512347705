import { depopulateRules, VARIABLE_TYPES, WORKPAPER_CONDITION_ACTIONS } from "@lb/utils";
import { useFormikContext } from "formik";
import { compact, find, has, isEmpty, map } from "lodash";
import React, { useMemo } from "react";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { values } = useFormikContext();

    const variables = useMemo(() => {
      return map(values.variables, (variable) => ({
        ...variable,
        type: variable?.type?.value,
      }));
    }, [values.variables]);

    const workflows = useMemo(() => {
      return map(values.workflows, (workflow) => ({
        actions: map(workflow.actions, ({ action, payload, target }) => {
          let selectAction = action?.value || action,
            targetVariable = find(variables, {
              name: target?.name || target,
            });
          return {
            action: selectAction,
            payload:
              {
                [WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE]:
                  {
                    [VARIABLE_TYPES.key.MCQ]: targetVariable?.multiple ? compact(map(payload, "value") || []) : payload.value || payload,
                  }[targetVariable?.type] || payload,
                [WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS]: compact(map(payload, "value")),
              }[selectAction] || payload,
            target: targetVariable?.name,
          };
        }),
        altActions: map(workflow.altActions, ({ action, payload, target }) => {
          let selectAction = action?.value || action,
            targetVariable = find(variables, {
              name: target?.name,
            });
          return {
            action: selectAction,
            payload:
              {
                [WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE]:
                  {
                    [VARIABLE_TYPES.key.MCQ]: targetVariable?.multiple ? compact(map(payload, "value")) || [] : payload.value || payload,
                  }[targetVariable?.type] || payload,
                [WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS]: compact(map(payload, "value")),
              }[selectAction] || payload,
            target: targetVariable?.name,
          };
        }),
        conjunction: workflow.conjunction,
        title: workflow.title,
        rules: depopulateRules(workflow.rules, variables),
      }));
    }, [values.workflows, variables]);

    return <Component {...{ ...props, variables, workflows }} />;
  };

export default engine;
