import { LbNumberField, LbSmartSelect, LbTextArea } from "@lb/frontend";
import { VARIABLE_TYPES, WORKPAPER_CONDITION_ACTIONS } from "@lb/utils";
import { Box, Divider, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import {
  compact,
  difference,
  endsWith,
  every,
  find,
  fromPairs,
  has,
  includes,
  isEmpty,
  isEqual,
  isNull,
  map,
  mapValues,
  some,
  startsWith,
  without,
} from "lodash";
import React, { Children, Fragment, useEffect, useState } from "react";

const operatorFunctions = {
  equals: (a, b) => isEqual(a, b),
  "not-equals": (a, b) => !isEqual(a, b),
  "less-than": (a, b) => a < b,
  "greater-than": (a, b) => a > b,
  "less-than-or-equal-to": (a, b) => a <= b,
  "greater-than-or-equal-to": (a, b) => a >= b,
  contains: (a, b) => every(b, (val) => includes(a, val)),
  "begins-with": (a, b) => startsWith(a, b),
  "ends-with": (a, b) => endsWith(a, b),
  "does-not-contain": (a, b) => !every(b, (val) => includes(a, val)),
  "does-not-begin-with": (a, b) => !startsWith(a, b),
  "does-not-end-with": (a, b) => !endsWith(a, b),
  "is-null": (a) => isNull(a),
  "is-not-null": (a) => !isNull(a),
  in: (a, b) => includes(b, a),
  "not-in": (a, b) => !includes(b, a),
  between: (a, [low, high]) => a >= +low && a <= +high,
  "not-between": (a, [low, high]) => a < +low || a > +high,
};

const evaluateRule = (rule, variableValues) => {
  const operatorFunction = operatorFunctions[rule.operator];
  //! fallback
  if (!operatorFunction) return false;

  return operatorFunction(variableValues[rule.source], rule.value);
};

const validateRules = (ruleGroup, variableValues) => {
  const conjunction = ruleGroup.conjunction;
  const rules = ruleGroup.rules;

  if (conjunction === "and") {
    return every(rules, (rule) =>
      rule.rules ? validateRules(rule, variableValues) : evaluateRule(rule, variableValues)
    );
  } else if (conjunction === "or") {
    return some(rules, (rule) =>
      rule.rules ? validateRules(rule, variableValues) : evaluateRule(rule, variableValues)
    );
  }

  return false;
};

export default function WorkPage({ variables, workflows }) {
  const [hiddenFields, setHiddenFields] = useState([]);
  const [altOptions, setAltOptions] = useState({});

  const formik = useFormik({
    validateOnChange: false,
    initialValues: fromPairs(
      map(variables, (v) => [
        v.name,
        v.value || (v.type === VARIABLE_TYPES.key.MCQ && v.multiple) ? [] : "",
      ])
    ),
    enableReinitialize: true,
  });

  useEffect(() => {
    map(workflows, (workflow) => {
      const depopulatedValues = mapValues(formik?.values, (v) =>
        has(v, ["label"]) ? v?.value : has(v, [0, "label"]) ? compact(map(v, "value") || []) : v
      );

      let SHOULD_EXECUTE_ACTION = validateRules(workflow, depopulatedValues);

      if (SHOULD_EXECUTE_ACTION) {
        map(workflow.actions, ({ action, target, payload }) => {
          let targetVariable = find(variables, {
            name: target,
          });

          switch (action?.value || action) {
            case WORKPAPER_CONDITION_ACTIONS.key.SHOW:
              setHiddenFields((prev) => [...new Set(without(prev, targetVariable.name))]);
              break;

            case WORKPAPER_CONDITION_ACTIONS.key.HIDE:
              formik.setFieldValue(target, "");
              setHiddenFields((prev) => [...new Set([...prev, targetVariable.name])]);
              break;

            case WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS:
              if (targetVariable.type === VARIABLE_TYPES.key.MCQ) {
                if (targetVariable.multiple) {
                  //! if target value is not in the payload, i.e options
                  if (
                    formik.values[target] &&
                    !isEmpty(difference(map(formik.values[target], "value"), payload))
                  ) {
                    formik.setFieldValue(target, "");
                  }
                } else {
                  if (
                    formik.values[target]?.value &&
                    !includes(payload, formik.values[target]?.value)
                  ) {
                    formik.setFieldValue(target, "");
                  }
                }
              }

              setAltOptions((prev) => ({
                ...prev,
                [targetVariable.name]: payload,
              }));
              break;

            case WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE:
              if (!includes(hiddenFields, target)) {
                let val =
                  targetVariable.type === VARIABLE_TYPES.key.MCQ
                    ? targetVariable.multiple
                      ? map(payload, (v) => ({ label: v, value: v }))
                      : { label: payload, value: payload }
                    : payload;

                if (!isEqual(formik.values[target], val)) {
                  formik.setFieldValue(target, val);
                }
              }
              break;

            default:
              break;
          }
        });
      } else {
        map(workflow.altActions, ({ action, target, payload }) => {
          let targetVariable = find(variables, {
            name: target,
          });

          switch (action?.value || action) {
            case WORKPAPER_CONDITION_ACTIONS.key.SHOW:
              setHiddenFields((prev) => [...new Set(without(prev, targetVariable.name))]);
              break;

            case WORKPAPER_CONDITION_ACTIONS.key.HIDE:
              formik.setFieldValue(target, "");
              setHiddenFields((prev) => [...new Set([...prev, targetVariable.name])]);
              break;

            case WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS:
              if (targetVariable.type === VARIABLE_TYPES.key.MCQ) {
                if (targetVariable.multiple) {
                  //! if target value is not in the payload, i.e options
                  if (
                    formik.values[target] &&
                    !isEmpty(difference(map(formik.values[target], "value"), payload))
                  ) {
                    formik.setFieldValue(target, "");
                  }
                } else {
                  if (
                    formik.values[target]?.value &&
                    !includes(payload, formik.values[target]?.value)
                  ) {
                    formik.setFieldValue(target, "");
                  }
                }
              }

              setAltOptions((prev) => ({
                ...prev,
                [targetVariable.name]: payload,
              }));
              break;

            case WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE:
              if (!includes(hiddenFields, target)) {
                let val =
                  targetVariable.type === VARIABLE_TYPES.key.MCQ
                    ? targetVariable.multiple
                      ? map(payload, (v) => ({ label: v, value: v }))
                      : { label: payload, value: payload }
                    : payload;

                if (!isEqual(formik.values[target], val)) {
                  formik.setFieldValue(target, val);
                }
              }
              break;

            default:
              break;
          }
        });
      }
    });
  }, [formik.values]);

  return (
    <Fragment>
      <FormikProvider value={formik}>
        <Form>
          <Stack component={"ol"} spacing={1} divider={<Divider />} sx={{ pl: 3 }}>
            {Children.toArray(
              map(variables, (variable, VARIABLE_INDEX) => {
                const variableOptions = altOptions[variable.name] || variable.options;

                if (includes(hiddenFields, variable.name)) return null;
                return (
                  <Typography component={"li"} fontWeight="bolder" variant="body2" width="100%" dis>
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      spacing={3}
                      alignItems={"center"}
                      divider={<Divider flexItem orientation="vertical" variant="middle" />}
                    >
                      <Box flex={2}>
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          color="text.primary"
                          textAlign="justify"
                          display="block"
                        >
                          {variable.label}
                        </Typography>

                        {variable.description && (
                          <FormHelperText sx={{ ml: 0 }}>{variable.description}</FormHelperText>
                        )}
                      </Box>
                      <Box flex={1} pb={1}>
                        {
                          {
                            text: (
                              <LbTextArea
                                inputProps={{
                                  minRows: 2,
                                }}
                                name={variable.name}
                                placeholder={variable.placeholder}
                                boxProps={{ mt: 1 }}
                              />
                            ),

                            mcq: (
                              <LbSmartSelect
                                multiple={variable.multiple}
                                selectAll={variable.selectAll}
                                name={variable.name}
                                placeholder={variable.placeholder}
                                options={map(variableOptions, (option) => ({
                                  label: option === "*" ? "Select All (*)" : option,
                                  value: option,
                                }))}
                                sx={{ mt: 1 }}
                                fullWidth={false}
                              />
                            ),

                            // mcq:
                            //   size(variableOptions) > 4 ? (
                            //     <LbSmartSelect
                            //       multiple={variable.multiple}
                            //       selectAll={variable.selectAll}
                            //       name={variable.name}
                            //       placeholder={variable.placeholder}
                            //       options={map(variableOptions, (option) => ({
                            //         label: option,
                            //         value: option,
                            //       }))}
                            //       sx={{ mt: 1 }}
                            //       fullWidth={false}
                            //       aria-labelledby={`${variable.name}-label`}
                            //     />
                            //   ) : variable?.multiple ? (
                            //     <FormGroup row aria-labelledby={`${variable.name}-label`}>
                            //       {Children.toArray(
                            //         map(variableOptions, (option) => {
                            //           return (
                            //             <FormControlLabel
                            //               value={option}
                            //               control={
                            //                 <Checkbox
                            //                   name={option}
                            //                   size="small"
                            //                   checked={includes(formik.values[variable.name], option)}
                            //                   onChange={(event) => {
                            //                     formik.setFieldValue(
                            //                       variable.name,
                            //                       event.target.checked
                            //                         ? [...formik.values?.[variable.name], option]
                            //                         : without(formik.values[variable.name], option)
                            //                     );
                            //                   }}
                            //                 />
                            //               }
                            //               label={option}
                            //               // sx={{ minWidth: "calc(100% / 4.5)" }}
                            //               componentsProps={{
                            //                 typography: { variant: "body2" },
                            //               }}
                            //             />
                            //           );
                            //         })
                            //       )}
                            //     </FormGroup>
                            //   ) : (
                            //     <RadioGroup
                            //       row
                            //       aria-labelledby={`${variable.name}-radio-buttons-group-label`}
                            //       name={variable.name}
                            //       value={formik.values[variable.name]}
                            //       onChange={(event) => {
                            //         formik.setFieldValue(variable.name, event.target.value);
                            //       }}
                            //     >
                            //       {Children.toArray(
                            //         map(variableOptions, (option) => {
                            //           return (
                            //             <FormControlLabel
                            //               value={option}
                            //               control={<Radio size="small" />}
                            //               label={option}
                            //               sx={{ minWidth: "calc(100% / 4.5)" }}
                            //               componentsProps={{
                            //                 typography: { variant: "body2" },
                            //               }}
                            //             />
                            //           );
                            //         })
                            //       )}
                            //     </RadioGroup>
                            //   ),

                            number: (
                              <LbNumberField
                                min={variable.min}
                                max={variable.max}
                                fullWidth
                                name={variable.name}
                                placeholder={variable.placeholder}
                                sx={{ mt: 1 }}
                              />
                            ),

                            static: (
                              <TextField
                                value={variable.value}
                                fullWidth
                                inputProps={{
                                  "aria-readonly": true,
                                }}
                                InputProps={{ readOnly: true }}
                                name={variable.name}
                                placeholder={variable.placeholder}
                                size="small"
                                sx={{ mt: 1 }}
                              />
                            ),
                          }[variable.type]
                        }
                      </Box>
                    </Stack>
                  </Typography>
                );
              })
            )}
          </Stack>
        </Form>
      </FormikProvider>
    </Fragment>
  );
}
