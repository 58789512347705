import { LbSelect } from "@lb/frontend";
import { Box, Chip, Stack } from "@mui/material";
import { split } from "lodash";
import React from "react";
import { LuGitCommit } from "react-icons/lu";

function RuleHeader({
  name,
  pushRule,
  pushRuleGroup,
  connectorColor,
  removeRule,
}) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={3}
      width={"100%"}
      sx={{
        position: "relative",
        "&::before": {
          content: '""',
          width: "100%",
          position: "absolute",
          borderTopWidth: 2,
          borderTopStyle: "dashed",
          borderTopColor: connectorColor,
          top: "50%",
          left: split(name, ".rules.")?.length === 1 ? 0 : -45,
          transform: "translateY(-50%)",
          zIndex: -1,
        },
      }}
    >
      <Box flex={1}>
        <LbSelect
          name={`${name}.conjunction`}
          label="Conjunction"
          placeholder="Conjunction"
          options={[
            { label: "AND", value: "and" },
            { label: "OR", value: "or" },
          ]}
          boxProps={{ sx: { width: "100px", backgroundColor: "white" } }}
        />
      </Box>
      <Chip
        label="New Rule"
        icon={<LuGitCommit />}
        size="small"
        variant="outlined"
        color="primary"
        sx={{
          borderWidth: 1.5,
          backgroundColor: "white",
          "&:hover": { backgroundColor: "white!important" },
        }}
        onClick={pushRule}
      />
      <Chip
        label="New Group"
        icon={<LuGitCommit />}
        size="small"
        variant="outlined"
        color="warning"
        sx={{
          borderWidth: 1.5,
          backgroundColor: "white",
          "&:hover": { backgroundColor: "white!important" },
        }}
        onClick={pushRuleGroup}
      />

      <Chip
        label={"Remove"}
        icon={<LuGitCommit />}
        size="small"
        variant="outlined"
        color={removeRule ? "error" : "default"}
        sx={{
          borderWidth: 1.5,
          backgroundColor: "white",
          "&:hover": { backgroundColor: "white!important" },
          cursor: removeRule ? "pointer" : "not-allowed",
        }}
        onClick={removeRule}
      />
    </Stack>
  );
}

export default RuleHeader;
