import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import {
  attachClosestEdge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";
import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine"; // NEW
import {
  draggable,
  dropTargetForElements,
  monitorForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import { LbNumberField, LbSmartSelect, LbTextField, useMiscStore } from "@lb/frontend";
import {
  CIRCLE_ICON,
  depopulateVariables,
  DISC_ICON,
  EDITOR_NODE_TYPES,
  getNodeFragment,
  getNodeType,
  indexToListStyle,
  lbUid,
  LIST_STYLE_TYPE,
  objectToQueryParams,
  OPERATORS,
  SQUARE_ICON,
  VARIABLE_TYPES,
} from "@lb/utils";
import { ViewWeekOutlined } from "@mui/icons-material";
import DataObjectIcon from "@mui/icons-material/DataObject";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Field, FieldArray, useFormikContext } from "formik";
import {
  cloneDeep,
  compact,
  find,
  get,
  has,
  includes,
  isArray,
  isEmpty,
  isNumber,
  join,
  map,
  omit,
  pick,
  set,
  size,
  trim,
  upperCase,
} from "lodash";
import * as React from "react";
import { Children, Fragment, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import toast from "react-hot-toast";
import { BsTextParagraph } from "react-icons/bs";
import { GoNumber, GoTable, GoTypography, GoUnfold } from "react-icons/go";
import { LuGitCommit, LuLayoutTemplate } from "react-icons/lu";
import { RiFlowChart } from "react-icons/ri";
import { TiFlowMerge } from "react-icons/ti";
import invariant from "tiny-invariant";
import Attributes from "./components/Attributes";
import engine from "./engine";

const getNodeColor = (nodeType) => {
  return (
    {
      [EDITOR_NODE_TYPES.key.TEXT]: "#37474f", // Blue Gray
      [EDITOR_NODE_TYPES.key.PARAGRAPH]: "#43a047", // Vibrant Green
      [EDITOR_NODE_TYPES.key.OL]: "#1e88e5", // Strong Sky Blue
      [EDITOR_NODE_TYPES.key.UL]: "#ff4081", // Vivid Pink for unordered lists (informal, less structured)
      [EDITOR_NODE_TYPES.key.COLUMN]: "#ffca28", // Golden Yellow
      [EDITOR_NODE_TYPES.key.TABLE]: "#795548", // Chocolate Brown
      [EDITOR_NODE_TYPES.key.SPACE]: "#8e24aa", // Deep Violet
      [EDITOR_NODE_TYPES.key.STACK]: "#00bcd4", // Bright Teal
      [EDITOR_NODE_TYPES.key.LOOP]: "#e91e63", // Bright Pink
      [EDITOR_NODE_TYPES.key.VARIABLE]: "#d32f2f", // Crimson Red
      [EDITOR_NODE_TYPES.key.DECISION]: "#00897b", // Deep Teal for Decision
      [EDITOR_NODE_TYPES.key.RULES]: "#ff7043", // Warm Orange for Rules
      [EDITOR_NODE_TYPES.key.THEN]: "#6d4c41", // Dark Purple for Then
      [EDITOR_NODE_TYPES.key.ELSE]: "#5d4037", // Rich Brown for Else
    }[nodeType] || "black"
  );
};

const StyledChip = styled(Chip)(({ onClick }) => ({
  display: "flex",
  alignItems: "center",
  width: "fit-content",
  cursor: onClick ? "pointer" : "default",
  zIndex: 0.5,
  position: "relative",
  "&.MuiChip-sizeSmall": {
    fontSize: "12px",
    height: "fit-content",
  },
  "& .MuiChip-iconSmall": {
    fontSize: "12px",
  },
  borderWidth: 1.5,
  "&.MuiChip-outlined": {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white!important",
    },
  },
}));

function Manuscript() {
  return (
    <FieldArray name="manuscript">
      {(arrayHelpers) => (
        <Fragment>
          <AppBar
            position="sticky"
            color="default"
            variant="outlined"
            elevation={4}
            sx={{ top: 7, px: 1, overflowX: "auto" }}
          >
            <Stack
              direction="row"
              alignItems="center"
              flexWrap={"nowrap"}
              divider={
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ borderColor: "info.main", borderWidth: "1px", borderStyle: "dashed" }}
                />
              }
              sx={{ width: "100%" }}
              spacing={0}
              justifyContent={"space-between"}
            >
              {Children.toArray(
                [
                  {
                    icon: <GoTypography />,
                    text: EDITOR_NODE_TYPES._.text,
                    nodeType: EDITOR_NODE_TYPES.key.TEXT,
                  },
                  {
                    icon: <BsTextParagraph />,
                    text: EDITOR_NODE_TYPES._.paragraph,
                    nodeType: EDITOR_NODE_TYPES.key.PARAGRAPH,
                  },
                  {
                    icon: <GoNumber />,
                    text: EDITOR_NODE_TYPES._.ol,
                    nodeType: EDITOR_NODE_TYPES.key.OL,
                  },
                  {
                    icon: <ScatterPlotIcon />,
                    text: EDITOR_NODE_TYPES._.ul,
                    nodeType: EDITOR_NODE_TYPES.key.UL,
                  },
                  {
                    icon: <ViewWeekOutlined />,
                    text: EDITOR_NODE_TYPES._.column,
                    nodeType: EDITOR_NODE_TYPES.key.COLUMN,
                    disabled: true,
                  },
                  {
                    icon: <GoTable />,
                    text: EDITOR_NODE_TYPES._.table,
                    nodeType: EDITOR_NODE_TYPES.key.TABLE,
                    disabled: true,
                  },
                  {
                    icon: <GoUnfold />,
                    text: EDITOR_NODE_TYPES._.space,
                    nodeType: EDITOR_NODE_TYPES.key.SPACE,
                  },
                  {
                    icon: <LuLayoutTemplate />,
                    text: EDITOR_NODE_TYPES._.stack,
                    nodeType: EDITOR_NODE_TYPES.key.STACK,
                  },
                  {
                    icon: <DataObjectIcon />,
                    text: EDITOR_NODE_TYPES._.variable,
                    nodeType: EDITOR_NODE_TYPES.key.VARIABLE,
                  },
                  {
                    icon: <TiFlowMerge />,
                    text: EDITOR_NODE_TYPES._.decision,
                    nodeType: EDITOR_NODE_TYPES.key.DECISION,
                  },
                  {
                    icon: <RiFlowChart />,
                    text: EDITOR_NODE_TYPES._.loop,
                    disabled: true,
                  },
                ].map((item) => <Control {...item} {...{ arrayHelpers }} />)
              )}
            </Stack>
          </AppBar>
          <Divider />

          <Editor {...{ arrayHelpers }} />
        </Fragment>
      )}
    </FieldArray>
  );
}

const cleanedArray = (arr) => {
  return compact(
    map(arr, (s) => {
      if (isArray(s?.text)) s.text = cleanedArray(s?.text);
      if (isArray(s?.ul)) s.ul = cleanedArray(s?.ul);
      if (isArray(s?.ol)) s.ol = cleanedArray(s?.ol);
      if (isArray(s?.stack)) s.stack = cleanedArray(s?.stack);
      if (isArray(s?.column)) s.column = cleanedArray(s?.column);
      if (isArray(s?.then)) s.else = cleanedArray(s?.then);
      if (isArray(s?.else)) s.else = cleanedArray(s?.else);
      return s;
    })
  );
};

const Editor = React.memo(({ arrayHelpers }) => {
  const ref = useRef(null);
  const { values, setFieldValue } = useFormikContext();
  console.log("🚀 ~ Editor ~ values:", values.manuscript);
  const [aboutToDrop, setAboutToDrop] = useState(false);

  useEffect(() => {
    return monitorForElements({
      onDrop({ location, source }) {
        const target = location.current.dropTargets[0];

        if (!target || target.data.index < 0 || includes(target.data.name, source.data.name)) {
          return;
        }

        const closestEdgeOfTarget = extractClosestEdge(target.data);
        if (!closestEdgeOfTarget) return;

        const finishIndex = getReorderDestinationIndex({
          startIndex: source.data.index,
          closestEdgeOfTarget,
          indexOfTarget: target.data.index,
          axis: "vertical",
        });

        if (
          finishIndex === source.data.index &&
          target.data.arrayHelpers.name === source.data.arrayHelpers.name
        ) {
          return;
        }

        if (
          typeof source.data.index === "number" &&
          !isEmpty(target.data.arrayHelpers) &&
          target.data.arrayHelpers.name
        ) {
          invariant(source.data.arrayHelpers);
          //! Moving inside same parent
          if (target.data.arrayHelpers.name === source.data.arrayHelpers.name) {
            target.data.arrayHelpers.move(source.data.index, finishIndex);
          }
          //! Moving accross nodes
          else {
            let manuscriptData = { manuscript: cloneDeep(values.manuscript) };
            const sourceData = get(manuscriptData, source.data.name);
            set(manuscriptData, source.data.name, undefined);

            const INSERT_INDEX =
              closestEdgeOfTarget === "bottom" ? target.data.index + 1 : target.data.index;
            get(manuscriptData, target.data.arrayHelpers.name).splice(INSERT_INDEX, 0, sourceData);
            manuscriptData.manuscript = compact(
              map(manuscriptData.manuscript, (script) => {
                if (isArray(script?.text)) script.text = cleanedArray(script.text);
                if (isArray(script?.ul)) script.ul = cleanedArray(script?.ul);
                if (isArray(script?.ol)) script.ol = cleanedArray(script?.ol);
                if (isArray(script?.stack)) script.stack = cleanedArray(script?.stack);
                if (isArray(script?.column)) script.column = cleanedArray(script?.column);
                if (isArray(script?.then)) script.then = cleanedArray(script?.then);
                if (isArray(script?.else)) script.else = cleanedArray(script?.else);
                return script;
              })
            );
            setFieldValue("manuscript", manuscriptData.manuscript);
          }
          return;
        }

        if (typeof source.data.nodeType === "string")
          target.data.arrayHelpers.insert(finishIndex, getNodeFragment(source.data.nodeType));
      },
    });
  }, [values.manuscript]);

  useEffect(() => {
    const element = ref.current;
    invariant(element);

    return combine(
      dropTargetForElements({
        element,
        onDragLeave: () => {
          setAboutToDrop(false);
        },
        onDragEnter: () => {
          setAboutToDrop(true);
        },
        onDragStart: () => {
          setAboutToDrop(true);
        },
        onDrop: ({ source, self, location }) => {
          setAboutToDrop(false);
        },
      }),
      autoScrollForElements({
        element,
      })
    );
  }, []);

  return (
    <Fragment>
      <Stack
        {...{
          ref,
          sx: {
            backgroundColor: aboutToDrop ? "#f0f0f0" : "white",
            border: "2px dashed",
            borderColor: aboutToDrop ? "primary.main" : "transparent",
            p: 1,
            minHeight: "50vh",
            overflow: "auto",
          },
        }}
      >
        {isEmpty(values.manuscript) ? (
          <EmptyStructurePlaceholder
            {...{
              index: 0,
              name: `manuscript[${0}]`,
              arrayHelpers,
            }}
          />
        ) : (
          Children.toArray(
            map(values.manuscript, (script, SCRIPT_INDEX) => {
              return (
                <Structure
                  {...{
                    index: SCRIPT_INDEX,
                    name: `manuscript[${SCRIPT_INDEX}]`,
                    arrayHelpers,
                    nodeType: getNodeType(script),
                  }}
                />
              );
            })
          )
        )}
      </Stack>
    </Fragment>
  );
});

const Control = React.memo(({ arrayHelpers, ...props }) => {
  const ref = useRef(null);
  const [isDragging, setDragging] = useState(false);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;
    return draggable({
      element,
      getInitialData: () => ({ nodeType: props.nodeType }),
      onDragStart() {
        setDragging(true);
      },
      onDrop() {
        setDragging(false);
        setPreview(null);
      },
      onGenerateDragPreview({ nativeSetDragImage }) {
        setCustomNativeDragPreview({
          nativeSetDragImage,
          render({ container }) {
            setPreview(container);
          },
        });
      },
    });
  }, []);

  return (
    <Button
      ref={ref}
      variant="text"
      size="small"
      startIcon={props.icon}
      onClick={() => arrayHelpers.push(getNodeFragment(props.nodeType))}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        cursor: isDragging ? "grabbing" : "grab",
        textWrap: "nowrap",
        color: "grey.600",
        "&:hover": {
          color: "primary.main",
        },
      }}
      disabled={props.disabled}
    >
      {props.text}
      {preview && createPortal(<ControlPreview {...props} />, preview)}
    </Button>
  );
});

const ControlPreview = ({ ...props }) => {
  return (
    <Paper sx={{ py: 0.5, px: 2, border: "2px solid", borderColor: "primary.main", opacity: 1 }}>
      <Typography color="primary.main" m={0} variant="body2">
        {props.text}
      </Typography>
    </Paper>
  );
};

const assignNewIdentities = (script) => {
  const nodeType = getNodeType(script);
  script = {
    ...script,
    id: lbUid(),
  };
  switch (nodeType) {
    case EDITOR_NODE_TYPES.key.TEXT:
      return script;
    case EDITOR_NODE_TYPES.key.PARAGRAPH:
      return {
        ...script,
        text: compact(map(script.text, (s) => assignNewIdentities(s))),
      };
    case EDITOR_NODE_TYPES.key.OL:
      return {
        ...script,
        ol: compact(map(script.ol, (s) => assignNewIdentities(s))),
      };
    case EDITOR_NODE_TYPES.key.UL:
      return {
        ...script,
        ul: compact(map(script.ul, (s) => assignNewIdentities(s))),
      };
    case EDITOR_NODE_TYPES.key.SPACE:
      return script;
    case EDITOR_NODE_TYPES.key.STACK:
      return {
        ...script,
        stack: compact(map(script.stack, (s) => assignNewIdentities(s))),
      };
    case EDITOR_NODE_TYPES.key.VARIABLE:
      return {
        ...script,
        variable: script.variable.name,
      };
    case EDITOR_NODE_TYPES.key.DECISION:
      return {
        ...script,
        then: compact(map(script.then, (s) => assignNewIdentities(s))),
        else: compact(map(script.else, (s) => assignNewIdentities(s))),
      };
    default:
      return script;
  }
};

const getNodeBadge = ({ index, nodeType, parentNodeType, parentAttributes, conjunction }) => {
  const nodeColor = trim(getNodeColor(nodeType), "#");
  const parentNodeColor = trim(getNodeColor(parentNodeType), "#");
  const nodeName = `${EDITOR_NODE_TYPES._[nodeType]}`;

  if (parentNodeType === EDITOR_NODE_TYPES.key.UL) {
    return `https://img.shields.io/badge/_-${nodeName}-${nodeColor}.svg?${objectToQueryParams({
      labelColor: parentNodeColor,
      logo: {
        [LIST_STYLE_TYPE.ul.key.DISC]: DISC_ICON,
        [LIST_STYLE_TYPE.ul.key.CIRCLE]: CIRCLE_ICON,
        [LIST_STYLE_TYPE.ul.key.SQUARE]: SQUARE_ICON,
      }[parentAttributes?.type],
    })}`;
  }

  if (parentNodeType === EDITOR_NODE_TYPES.key.OL) {
    return `https://img.shields.io/badge/${indexToListStyle(
      index,
      parentAttributes?.type
    )}-${nodeName}-${nodeColor}?${objectToQueryParams({
      labelColor: parentNodeColor,
    })}`;
  }

  if (nodeType === EDITOR_NODE_TYPES.key.RULES) {
    return `https://img.shields.io/badge/${upperCase(
      conjunction
    )}-${nodeName}-${nodeColor}?${objectToQueryParams({
      labelColor: parentNodeColor,
    })}`;
  }

  return `https://img.shields.io/badge/${nodeName}-${nodeColor}`;
};

const Structure = React.memo(
  ({ name, index, arrayHelpers, parentAttributes, parentNodeType, nodeType }) => {
    const ref = useRef(null);
    const dragHandleRef = useRef(null);
    const [isDragging, setDragging] = useState(false);
    const [preview, setPreview] = useState(null);
    const { values } = useFormikContext();
    const [closestEdge, setClosestEdge] = useState(null);

    const { misc, storeMisc } = useMiscStore();

    const onlyChild = useMemo(
      () => size(get(values, arrayHelpers.name)) < 2,
      [arrayHelpers.name, values]
    );

    const canDrag = useMemo(() => {
      if (includes([EDITOR_NODE_TYPES.key.THEN, EDITOR_NODE_TYPES.key.ELSE], nodeType))
        return false;

      if (onlyChild) return false;

      return true;
    }, [nodeType, onlyChild]);

    const [configAnchorEl, setConfigAnchorEl] = React.useState(null);

    const openConfig = (event) => {
      setConfigAnchorEl(event.currentTarget);
    };

    const nodeData = React.useMemo(() => {
      return get(values, `${name}`) || {};
    }, [name, values]);

    const nodeName = useMemo(() => `${EDITOR_NODE_TYPES._[nodeType]}`, [nodeType]);

    const nodeBadge = useMemo(
      () => getNodeBadge({ index, nodeType, parentNodeType, parentAttributes }),
      [index, nodeType, parentAttributes, parentNodeType]
    );

    const copyId = async () => {
      try {
        await navigator.clipboard.writeText(nodeData.id);
        toast.success(`Node id "${nodeData.id}" copied successfully!`);
      } catch (error) {}
    };

    const duplicate = () => {
      arrayHelpers.insert(index + 1, { ...assignNewIdentities(nodeData, nodeType), id: lbUid() });
    };

    useEffect(() => {
      const element = ref.current;
      const dragHandle = dragHandleRef.current;
      invariant(element);
      invariant(dragHandle);
      return combine(
        draggable({
          element,
          dragHandle,
          canDrag: () => canDrag,
          getInitialData: () => ({ name, index, arrayHelpers, nodeType }),
          onDragStart() {
            setDragging(true);
          },
          onDrop() {
            setDragging(false);
            setPreview(null);
          },
          onGenerateDragPreview({ nativeSetDragImage }) {
            setCustomNativeDragPreview({
              nativeSetDragImage,
              render({ container }) {
                setPreview(container);
              },
            });
          },
        }),
        dropTargetForElements({
          element,
          getData({ input }) {
            return attachClosestEdge(
              { name, index, arrayHelpers, nodeType },
              {
                element,
                input,
                allowedEdges: ["top", "bottom"],
              }
            );
          },
          canDrop({ element, source, input }) {
            if (parentNodeType === EDITOR_NODE_TYPES.key.DECISION) {
              return false;
            }

            if (parentNodeType === EDITOR_NODE_TYPES.key.PARAGRAPH) {
              if (
                includes(
                  [EDITOR_NODE_TYPES.key.TEXT, EDITOR_NODE_TYPES.key.VARIABLE],
                  source.data.nodeType
                )
              ) {
                return true;
              } else {
                return false;
              }
            }
            return !isDragging;
          },
          onDrag({ self, source, location }) {
            const isSource = source.element === element;
            if (isSource) {
              setClosestEdge(null);
              return;
            }
            const closestEdge = extractClosestEdge(self.data);
            let isDropIndicatorHidden = false;
            isDropIndicatorHidden =
              isDropIndicatorHidden ||
              self.data.name !== location.current.dropTargets[0].data.name ||
              includes(location.current.dropTargets[0].data.name, source.data.name);

            if (isDropIndicatorHidden) {
              setClosestEdge(null);
              return;
            }

            setClosestEdge(closestEdge);
          },
          onDragLeave() {
            setClosestEdge(null);
          },
          onDrop() {
            setClosestEdge(null);
          },
        })
      );
    }, [arrayHelpers, canDrag, index, isDragging, name, nodeType, onlyChild, parentNodeType]);

    return (
      <Fragment>
        <Box
          ref={ref}
          sx={{
            opacity: isDragging ? 0.2 : 1,
            position: "relative",
            py: 1,
          }}
        >
          <Box
            {...{
              component: "fieldset",
              sx: {
                borderTopLeftRadius: 8,
                borderWidth: 1.2,
                borderStyle: "solid",
                borderColor: getNodeColor(nodeType),
                borderRight: 0,
                borderBottom: 0,
                pt: 0,
                pb: 0,
                pr: 0,
                m: 0,
                pl: 0,
              },
            }}
          >
            <Box {...{ component: "legend", width: "100%", p: 0, m: 0 }}>
              <Stack
                {...{
                  direction: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  spacing: 2,
                  width: "100%",
                  sx: {
                    position: "relative",
                    "&::before": {
                      content: '""',
                      width: "100%",
                      position: "absolute",
                      borderTopWidth: 1.2,
                      borderTopStyle: "solid",
                      borderTopColor: getNodeColor(nodeType),
                      top: "50%",
                      left:
                        size(name.match(/\[\d+\]/g)) > 1 ||
                        includes([EDITOR_NODE_TYPES.key.THEN, EDITOR_NODE_TYPES.key.ELSE], nodeType)
                          ? "-20px"
                          : 0,
                      transform: "translateY(-50%)",
                    },
                  },
                }}
              >
                <Box
                  flex={1}
                  ref={dragHandleRef}
                  sx={{ cursor: canDrag ? (isDragging ? "grabbing" : "grab") : "unset", zIndex: 0 }}
                >
                  <img src={nodeBadge} alt={nodeName} draggable={false} />
                </Box>

                {!includes(
                  [
                    EDITOR_NODE_TYPES.key.THEN,
                    EDITOR_NODE_TYPES.key.ELSE,
                    EDITOR_NODE_TYPES.key.DECISION,
                  ],
                  nodeType
                ) && (
                  <StyledChip
                    name={name}
                    label={`#${nodeData.id}`}
                    icon={<LuGitCommit />}
                    size="small"
                    variant={"outlined"}
                    color={isDragging ? "primary" : "default"}
                    onClick={copyId}
                  />
                )}

                {nodeType === EDITOR_NODE_TYPES.key.DECISION &&
                  (!isArray(nodeData?.else) || isEmpty(nodeData?.else)) && (
                    <StyledChip
                      name={name}
                      label={`Add Else`}
                      icon={<LuGitCommit />}
                      size="small"
                      variant={"outlined"}
                      color={isDragging ? "primary" : "default"}
                      onClick={() => {
                        arrayHelpers.form.setFieldValue(`${name}.else`, [
                          getNodeFragment(EDITOR_NODE_TYPES.key.TEXT),
                        ]);
                      }}
                    />
                  )}

                {!includes([EDITOR_NODE_TYPES.key.THEN, EDITOR_NODE_TYPES.key.ELSE], nodeType) && (
                  <StyledChip
                    name={name}
                    label={`Duplicate`}
                    icon={<LuGitCommit />}
                    size="small"
                    variant={"outlined"}
                    color={isDragging ? "primary" : "default"}
                    onClick={duplicate}
                  />
                )}

                {includes(
                  [
                    EDITOR_NODE_TYPES.key.OL,
                    EDITOR_NODE_TYPES.key.UL,
                    EDITOR_NODE_TYPES.key.PARAGRAPH,
                    EDITOR_NODE_TYPES.key.STACK,
                    EDITOR_NODE_TYPES.key.COLUMN,
                    EDITOR_NODE_TYPES.key.TABLE,
                    EDITOR_NODE_TYPES.key.DECISION,
                  ],
                  nodeType
                ) && (
                  <StyledChip
                    name={name}
                    label={misc[nodeData.id] ? "Expand" : "Collapse"}
                    icon={<LuGitCommit />}
                    size="small"
                    variant={"outlined"}
                    color={misc[nodeData.id] ? "success" : "warning"}
                    onClick={() => storeMisc({ [nodeData.id]: !misc[nodeData.id] })}
                  />
                )}

                {!includes(
                  [
                    EDITOR_NODE_TYPES.key.SPACE,
                    EDITOR_NODE_TYPES.key.PARAGRAPH,
                    EDITOR_NODE_TYPES.key.STACK,
                    EDITOR_NODE_TYPES.key.THEN,
                    EDITOR_NODE_TYPES.key.ELSE,
                  ],
                  nodeType
                ) && (
                  <StyledChip
                    data-node-name={name}
                    data-node-type={nodeType}
                    label="Config"
                    icon={<LuGitCommit />}
                    size="small"
                    variant="outlined"
                    color={"info"}
                    onClick={openConfig}
                  />
                )}

                {!onlyChild && !includes([EDITOR_NODE_TYPES.key.THEN], nodeType) && (
                  <StyledChip
                    label="Remove"
                    icon={<LuGitCommit />}
                    size="small"
                    variant="outlined"
                    color={"error"}
                    onClick={() => {
                      if (nodeType === EDITOR_NODE_TYPES.key.ELSE) {
                        arrayHelpers.form.setFieldValue(`${name}.else`, undefined);
                        return;
                      }
                      arrayHelpers.remove(index);
                    }}
                  />
                )}
              </Stack>
            </Box>

            <Collapse in={!misc[nodeData.id]} unmountOnExit>
              {!misc[nodeData.id] &&
                {
                  [EDITOR_NODE_TYPES.key.TEXT]: (
                    <Field
                      as={InputBase}
                      name={`${name}.text`}
                      multiline
                      fullWidth
                      sx={{
                        borderRadius: "5px",
                        backgroundColor: "beige",
                        px: 1,
                        "& textarea": {
                          textAlign: nodeData.alignment,
                          fontWeight: nodeData.bold ? "bolder" : "normal",
                          fontStyle: nodeData.italics ? "italic" : "normal",
                          textDecoration: join(nodeData.decoration, " "),
                        },
                      }}
                    />
                  ),
                  [EDITOR_NODE_TYPES.key.PARAGRAPH]: (
                    <FieldArray name={`${name}.text`}>
                      {(itemArrayHelpers) => {
                        return (
                          <Box ml={"20px"}>
                            {Children.toArray(
                              map(nodeData.text, (item, ITEM_INDEX) => {
                                return (
                                  <Structure
                                    {...{
                                      index: ITEM_INDEX,
                                      name: `${name}.text[${ITEM_INDEX}]`,
                                      arrayHelpers: itemArrayHelpers,
                                      parentAttributes: omit(nodeData, [
                                        EDITOR_NODE_TYPES.key.TEXT,
                                      ]),
                                      parentNodeType: nodeType,
                                      nodeType: getNodeType(item),
                                    }}
                                  />
                                );
                              })
                            )}
                          </Box>
                        );
                      }}
                    </FieldArray>
                  ),
                  [EDITOR_NODE_TYPES.key.OL]: (
                    <FieldArray name={`${name}.ol`}>
                      {(itemArrayHelpers) => {
                        return (
                          <Box ml={"20px"}>
                            {Children.toArray(
                              map(nodeData.ol, (item, ITEM_INDEX) => {
                                return (
                                  <Structure
                                    {...{
                                      index: ITEM_INDEX,
                                      name: `${name}.ol[${ITEM_INDEX}]`,
                                      arrayHelpers: itemArrayHelpers,
                                      parentAttributes: omit(nodeData, [EDITOR_NODE_TYPES.key.OL]),
                                      parentNodeType: nodeType,
                                      nodeType: getNodeType(item),
                                    }}
                                  />
                                );
                              })
                            )}
                          </Box>
                        );
                      }}
                    </FieldArray>
                  ),
                  [EDITOR_NODE_TYPES.key.UL]: (
                    <FieldArray name={`${name}.ul`}>
                      {(itemArrayHelpers) => {
                        return (
                          <Box ml={"20px"}>
                            {Children.toArray(
                              map(nodeData.ul, (item, ITEM_INDEX) => {
                                return (
                                  <Structure
                                    {...{
                                      index: ITEM_INDEX,
                                      name: `${name}.ul[${ITEM_INDEX}]`,
                                      arrayHelpers: itemArrayHelpers,
                                      parentAttributes: omit(nodeData, [EDITOR_NODE_TYPES.key.UL]),
                                      parentNodeType: nodeType,
                                      nodeType: getNodeType(item),
                                    }}
                                  />
                                );
                              })
                            )}
                          </Box>
                        );
                      }}
                    </FieldArray>
                  ),
                  [EDITOR_NODE_TYPES.key.SPACE]: (
                    <Typography sx={{ backgroundColor: "white", px: 1, width: "100%" }}>
                      {"\n"}
                    </Typography>
                  ),
                  [EDITOR_NODE_TYPES.key.STACK]: (
                    <FieldArray name={`${name}.stack`}>
                      {(itemArrayHelpers) => {
                        return (
                          <Box ml={"20px"}>
                            {Children.toArray(
                              map(nodeData.stack, (item, ITEM_INDEX) => {
                                return (
                                  <Structure
                                    {...{
                                      index: ITEM_INDEX,
                                      name: `${name}.stack[${ITEM_INDEX}]`,
                                      arrayHelpers: itemArrayHelpers,
                                      parentAttributes: omit(nodeData, [
                                        EDITOR_NODE_TYPES.key.STACK,
                                      ]),
                                      parentNodeType: nodeType,
                                      nodeType: getNodeType(item),
                                    }}
                                  />
                                );
                              })
                            )}
                          </Box>
                        );
                      }}
                    </FieldArray>
                  ),
                  [EDITOR_NODE_TYPES.key.VARIABLE]: (
                    <LbSmartSelect
                      required
                      valueKey="name"
                      name={`${name}.variable`}
                      options={values.variables}
                      placeholder="Select the variable"
                      inputProps={{
                        sx: {
                          "& .MuiInputBase-root": {
                            backgroundColor: "beige",
                            fontWeight: nodeData.bold ? "bolder" : "normal",
                            fontStyle: nodeData.italics ? "italic" : "normal",
                            textDecoration: join(nodeData.decoration, " "),
                            "& fieldset": {
                              border: 0,
                            },
                            "& input": {
                              textAlign: nodeData.alignment,
                              fontWeight: nodeData.bold ? "bolder" : "normal",
                              fontStyle: nodeData.italics ? "italic" : "normal",
                              textDecoration: join(nodeData.decoration, " "),
                            },
                          },
                        },
                      }}
                    />
                  ),
                  [EDITOR_NODE_TYPES.key.THEN]: (
                    <FieldArray name={`${name}.then`}>
                      {(itemArrayHelpers) => {
                        return (
                          <Box ml={"20px"}>
                            {Children.toArray(
                              map(nodeData.then, (item, ITEM_INDEX) => {
                                return (
                                  <Structure
                                    {...{
                                      index: ITEM_INDEX,
                                      name: `${name}.then[${ITEM_INDEX}]`,
                                      arrayHelpers: itemArrayHelpers,
                                      parentAttributes: {},
                                      parentNodeType: nodeType,
                                      nodeType: getNodeType(item),
                                    }}
                                  />
                                );
                              })
                            )}
                          </Box>
                        );
                      }}
                    </FieldArray>
                  ),
                  [EDITOR_NODE_TYPES.key.ELSE]: (
                    <FieldArray name={`${name}.else`}>
                      {(itemArrayHelpers) => {
                        return (
                          <Box ml={"20px"}>
                            {isEmpty(nodeData.else) ? (
                              <EmptyStructurePlaceholder
                                {...{
                                  index: 0,
                                  name: `${name}.else[${0}]`,
                                  arrayHelpers: itemArrayHelpers,
                                  parentAttributes: {},
                                  parentNodeType: nodeType,
                                }}
                              />
                            ) : (
                              Children.toArray(
                                map(nodeData.else, (item, ITEM_INDEX) => {
                                  return (
                                    <Structure
                                      {...{
                                        index: ITEM_INDEX,
                                        name: `${name}.else[${ITEM_INDEX}]`,
                                        arrayHelpers: itemArrayHelpers,
                                        parentAttributes: {},
                                        parentNodeType: nodeType,
                                        nodeType: getNodeType(item),
                                      }}
                                    />
                                  );
                                })
                              )
                            )}
                          </Box>
                        );
                      }}
                    </FieldArray>
                  ),
                  [EDITOR_NODE_TYPES.key.DECISION]: (
                    <Box pl={"20px"} pt={0.5}>
                      {/* //! Rules Block */}
                      <RuleGroupStructure name={name} />
                      {/* //! Then Block */}
                      <Structure
                        {...{
                          index,
                          name: `${name}`,
                          arrayHelpers: arrayHelpers,
                          parentAttributes: {},
                          parentNodeType: EDITOR_NODE_TYPES.key.DECISION,
                          nodeType: EDITOR_NODE_TYPES.key.THEN,
                        }}
                      />
                      {/* //! Else Block */}
                      {isArray(nodeData?.else) && !isEmpty(nodeData?.else) && (
                        <Structure
                          {...{
                            index,
                            name: `${name}`,
                            arrayHelpers: arrayHelpers,
                            parentAttributes: {},
                            parentNodeType: EDITOR_NODE_TYPES.key.DECISION,
                            nodeType: EDITOR_NODE_TYPES.key.ELSE,
                          }}
                        />
                      )}
                    </Box>
                  ),
                }[nodeType]}
            </Collapse>
          </Box>

          {closestEdge && <DropIndicator edge={closestEdge} gap="1px" />}
        </Box>
        {preview &&
          createPortal(
            <Paper
              sx={{ py: 0.5, px: 2, border: "2px solid", borderColor: "primary.main", opacity: 1 }}
            >
              <Typography color="primary.main" m={0} variant="body2">
                {nodeName}
              </Typography>
            </Paper>,
            preview
          )}

        {configAnchorEl &&
          createPortal(<Attributes {...{ setConfigAnchorEl, configAnchorEl }} />, configAnchorEl)}
      </Fragment>
    );
  }
);

function RuleGroupStructure({ name, removeRuleGroup }) {
  const { values } = useFormikContext();

  const nodeData = React.useMemo(() => {
    return get(values, `${name}`) || {};
  }, [name, values]);

  const [configAnchorEl, setConfigAnchorEl] = React.useState(null);

  const openConfig = (event) => {
    setConfigAnchorEl(event.currentTarget);
  };

  return (
    <Fragment>
      <FieldArray name={`${name}.rules`}>
        {(itemArrayHelpers) => {
          return (
            <Box
              {...{
                component: "fieldset",
                sx: {
                  borderTopLeftRadius: 8,
                  borderWidth: 1.2,
                  borderStyle: "solid",
                  borderColor: getNodeColor(EDITOR_NODE_TYPES.key.RULES),
                  borderRight: 0,
                  borderBottom: 0,
                  pt: 0,
                  pb: 0,
                  pr: 0,
                  m: 0,
                  pl: 0,
                },
              }}
            >
              <Box {...{ component: "legend", width: "100%", p: 0, m: 0 }}>
                <Stack
                  {...{
                    direction: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    spacing: 2,
                    width: "100%",
                    sx: {
                      position: "relative",
                      "&::before": {
                        content: '""',
                        width: "100%",
                        position: "absolute",
                        borderTopWidth: 1.2,
                        borderTopStyle: "solid",
                        borderTopColor: getNodeColor(EDITOR_NODE_TYPES.key.RULES),
                        top: "50%",
                        left: "-20px",
                        transform: "translateY(-50%)",
                      },
                    },
                  }}
                >
                  <Box flex={1} sx={{ zIndex: 0 }}>
                    <img
                      src={getNodeBadge({
                        nodeType: EDITOR_NODE_TYPES.key.RULES,
                        conjunction: nodeData.conjunction,
                      })}
                      alt={EDITOR_NODE_TYPES.key.RULES}
                      draggable={false}
                    />
                  </Box>

                  <StyledChip
                    label="New Rule"
                    icon={<LuGitCommit />}
                    size="small"
                    variant="outlined"
                    color={"secondary"}
                    onClick={() =>
                      itemArrayHelpers.push(getNodeFragment(EDITOR_NODE_TYPES.key.RULE))
                    }
                  />

                  <StyledChip
                    label="New Rule Group"
                    icon={<LuGitCommit />}
                    size="small"
                    variant="outlined"
                    color={"success"}
                    onClick={() =>
                      itemArrayHelpers.push(getNodeFragment(EDITOR_NODE_TYPES.key.RULES))
                    }
                  />

                  <StyledChip
                    data-node-name={name}
                    data-node-type={EDITOR_NODE_TYPES.key.RULES}
                    label="Config"
                    icon={<LuGitCommit />}
                    size="small"
                    variant="outlined"
                    color={"info"}
                    onClick={openConfig}
                  />

                  {removeRuleGroup && (
                    <StyledChip
                      label="Remove"
                      icon={<LuGitCommit />}
                      size="small"
                      variant="outlined"
                      color={"error"}
                      onClick={removeRuleGroup}
                    />
                  )}
                </Stack>
              </Box>

              <Stack spacing={3} pl={"20px"} py={1.5}>
                {Children.toArray(
                  map(nodeData.rules, (rule, ruleIndex) => {
                    if (rule.rules)
                      return (
                        <RuleGroupStructure
                          {...{ name: `${name}.rules[${ruleIndex}]` }}
                          removeRuleGroup={
                            nodeData.rules?.length > 1
                              ? () => itemArrayHelpers.remove(ruleIndex)
                              : null
                          }
                        />
                      );

                    return (
                      <RuleStructure
                        name={`${name}.rules[${ruleIndex}]`}
                        removeRule={
                          nodeData.rules?.length > 1
                            ? () => itemArrayHelpers.remove(ruleIndex)
                            : null
                        }
                      />
                    );
                  })
                )}
              </Stack>
            </Box>
          );
        }}
      </FieldArray>
      {configAnchorEl &&
        createPortal(<Attributes {...{ setConfigAnchorEl, configAnchorEl }} />, configAnchorEl)}
    </Fragment>
  );
}

function RuleStructure({ name, removeRule }) {
  const { values, setFieldValue } = useFormikContext();

  const variablesList = useMemo(
    () => depopulateVariables(values.variables) || [],
    [values.variables]
  );

  const VARIABLE_LIST_OPTIONS = useMemo(
    () => map(variablesList, (v) => pick(v, ["label", "name"])) || [],
    [variablesList]
  );

  const selectedSourceVar = useMemo(
    () => find(variablesList, { name: get(values, `${name}.source.name`) }) || {},
    [name, values, variablesList]
  );

  const variableResponseOptions = useMemo(
    () => map(selectedSourceVar?.options, (o) => ({ label: o, value: o })),
    [selectedSourceVar?.options]
  );

  const operatorOptions = useMemo(() => {
    return (
      {
        [VARIABLE_TYPES.key.MCQ]: selectedSourceVar?.multiple
          ? OPERATORS.mmcq.labelValue
          : OPERATORS.mcq.labelValue,
        [VARIABLE_TYPES.key.TEXT]: OPERATORS.text.labelValue,
        [VARIABLE_TYPES.key.STATIC]: OPERATORS.text.labelValue,
        [VARIABLE_TYPES.key.NUMBER]: OPERATORS.number.labelValue,
      }[selectedSourceVar?.type] || OPERATORS.all.labelValue
    );
  }, [selectedSourceVar]);

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        position: "relative",
        "&::before": {
          content: '""',
          width: "100%",
          position: "absolute",
          borderTopWidth: 1.2,
          borderTopStyle: "solid",
          borderTopColor: getNodeColor(EDITOR_NODE_TYPES.key.RULES),
          top: "20px",
          left: "-20px",
          zIndex: 0,
        },
      }}
    >
      <LbSmartSelect
        placeholder="Select variable"
        required
        name={`${name}.source`}
        label="Variable"
        options={VARIABLE_LIST_OPTIONS}
        valueKey="name"
        callback={(selectedVariable) => {
          setFieldValue(`${name}.value`, "");

          const sv = find(values.variables, { name: selectedVariable?.name });
          const so = get(values, `${name}.operator`)?.value;

          if (isEmpty(!sv)) {
            if (sv?.type === VARIABLE_TYPES.key.MCQ && sv?.multiple) {
              if (!includes(OPERATORS.mmcq.keys, so)) setFieldValue(`${name}.operator`, "");
            }
            if (sv?.type === VARIABLE_TYPES.key.MCQ && !sv?.multiple) {
              if (!includes(OPERATORS.mcq.keys, so)) setFieldValue(`${name}.operator`, "");
            }
          }

          if (sv?.type === VARIABLE_TYPES.key.NUMBER) {
            if (!includes(OPERATORS.number.keys, so)) setFieldValue(`${name}.operator`, "");
          }

          if (sv?.type === VARIABLE_TYPES.key.TEXT || sv?.type === VARIABLE_TYPES.key.STATIC) {
            if (!includes(OPERATORS.text.keys, so)) setFieldValue(`${name}.operator`, "");
          }
        }}
        inputProps={{
          sx: {
            "& .MuiInputBase-root": {
              backgroundColor: "beige",
            },
          },
        }}
      />

      <LbSmartSelect
        required
        placeholder="Select operator"
        name={`${name}.operator`}
        label={`${upperCase(selectedSourceVar?.type?.value || selectedSourceVar?.type)} Operator`}
        disabled={isEmpty(get(values, `${name}.source`))}
        options={operatorOptions}
        callback={(selected) => {
          if (isEmpty(!selectedSourceVar)) {
            const variableValue = get(values, `${name}.value`);

            if (selectedSourceVar?.type === VARIABLE_TYPES.key.MCQ && selectedSourceVar?.multiple) {
              if (!has(variableValue, [0, "label"])) {
                setFieldValue(`${name}.value`, "");
              }
            }

            if (
              selectedSourceVar?.type === VARIABLE_TYPES.key.MCQ &&
              !selectedSourceVar?.multiple
            ) {
              switch (selected?.value) {
                case OPERATORS.all.key.EQUALS:
                  if (!has(variableValue, ["label"])) setFieldValue(`${name}.value`, "");
                  break;
                case OPERATORS.all.key.NOT_EQUALS:
                  if (!has(variableValue, ["label"])) setFieldValue(`${name}.value`, "");
                  break;
                case OPERATORS.all.key.IN:
                  if (!has(variableValue, [0, "label"])) setFieldValue(`${name}.value`, "");
                  break;
                case OPERATORS.all.key.NOT_IN:
                  if (!has(variableValue, [0, "label"])) setFieldValue(`${name}.value`, "");
                  break;
                default:
                  break;
              }
            }

            if (selectedSourceVar?.type === VARIABLE_TYPES.key.NUMBER) {
              if (
                includes(
                  [OPERATORS.all.key.NOT_BETWEEN, OPERATORS.all.key.BETWEEN],
                  selected?.value
                )
              ) {
                if (!isArray(variableValue)) {
                  setFieldValue(`${name}.value`, ["", ""]);
                }
              } else {
                if (!isNumber(variableValue)) {
                  setFieldValue(`${name}.value`, "");
                }
              }
            }
          }
        }}
        inputProps={{
          sx: {
            "& .MuiInputBase-root": {
              backgroundColor: "beige",
            },
            ".MuiAutocomplete-inputRoot": {
              backgroundColor: "beige",
            },
          },
        }}
      />

      {{
        [VARIABLE_TYPES.key.MCQ]: (
          <LbSmartSelect
            placeholder="Select value"
            required
            name={`${name}.value`}
            options={variableResponseOptions}
            disabled={
              isEmpty(get(values, `${name}.operator`)) || isEmpty(get(values, `${name}.source`))
            }
            label="Variable value"
            multiple={
              includes(
                [
                  OPERATORS.all.key.IN,
                  OPERATORS.all.key.NOT_IN,
                  OPERATORS.all.key.CONTAINS,
                  OPERATORS.all.key.DOES_NOT_CONTAINS,
                ],
                get(values, `${name}.operator.value`)
              ) || selectedSourceVar?.multiple
            }
            selectAll={selectedSourceVar.selectAll}
            inputProps={{
              sx: {
                "&.MuiInputBase-root": {
                  backgroundColor: "beige!important",
                },
                ".MuiAutocomplete-inputRoot": {
                  backgroundColor: "beige!important",
                },
              },
            }}
          />
        ),
        [VARIABLE_TYPES.key.NUMBER]: !includes(
          [OPERATORS.number.key.BETWEEN, OPERATORS.number.key.NOT_BETWEEN],
          get(values, `${name}.operator.value`)
        ) ? (
          <LbNumberField
            placeholder="00"
            required
            name={`${name}.value`}
            disabled={
              isEmpty(get(values, `${name}.operator`)) || isEmpty(get(values, `${name}.source`))
            }
            label="Variable value"
            fullWidth
            sx={{ backgroundColor: "beige" }}
          />
        ) : (
          <Fragment>
            <LbNumberField
              placeholder="00"
              required
              name={`${name}.value.0`}
              disabled={
                isEmpty(get(values, `${name}.operator`)) || isEmpty(get(values, `${name}.source`))
              }
              label="Variable low"
              fullWidth
              sx={{ backgroundColor: "beige" }}
            />
            <LbNumberField
              placeholder="00"
              required
              name={`${name}.value.1`}
              disabled={
                isEmpty(get(values, `${name}.operator`)) || isEmpty(get(values, `${name}.source`))
              }
              label="Variable high"
              fullWidth
              sx={{ backgroundColor: "beige" }}
            />
          </Fragment>
        ),
      }[selectedSourceVar?.type] || (
        <LbTextField
          required
          name={`${name}.value`}
          label="Variable value"
          placeholder="Enter the variable value"
          sx={{
            "& .MuiInputBase-root": {
              backgroundColor: "beige",
            },
          }}
          disabled={
            isEmpty(get(values, `${name}.operator`)) || isEmpty(get(values, `${name}.source`))
          }
        />
      )}

      <Box pt={1.45}>
        <StyledChip
          sx={{
            cursor: removeRule ? "pointer" : "not-allowed",
            pointerEvents: removeRule ? "all" : "none",
          }}
          label="Remove"
          icon={<LuGitCommit />}
          size="small"
          variant="outlined"
          color={removeRule ? "error" : "default"}
          onClick={removeRule}
        />
      </Box>
    </Stack>
  );
}

const EmptyStructurePlaceholder = ({ name, index, arrayHelpers, parentNodeType }) => {
  const ref = useRef(null);
  const [closestEdge, setClosestEdge] = useState(null);

  useEffect(() => {
    const element = ref.current;
    invariant(element);
    return combine(
      dropTargetForElements({
        element,
        getData({ input }) {
          return attachClosestEdge(
            { name, index, arrayHelpers },
            {
              element,
              input,
              allowedEdges: ["top", "bottom"],
            }
          );
        },
        onDrag({ self, source, location }) {
          const isSource = source.element === element;
          if (isSource) {
            setClosestEdge(null);
            return;
          }
          const closestEdge = extractClosestEdge(self.data);
          let isDropIndicatorHidden = false;
          isDropIndicatorHidden =
            isDropIndicatorHidden ||
            self.data.name !== location.current.dropTargets[0].data.name ||
            includes(location.current.dropTargets[0].data.name, source.data.name);

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          setClosestEdge(closestEdge);
        },
        onDragLeave() {
          setClosestEdge(null);
        },
        onDrop() {
          setClosestEdge(null);
        },
      })
    );
  }, [arrayHelpers, index, name, parentNodeType]);
  return (
    <Box ref={ref} position={"relative"} py={2}>
      <Box position={"relative"}>{closestEdge && <DropIndicator edge={"top"} gap="1px" />}</Box>
    </Box>
  );
};

export default engine(Manuscript);
