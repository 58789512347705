import { LbNumberField, LbSmartSelect, LbTextArea } from "@lb/frontend";
import { depopulateWorkpaper, VARIABLE_TYPES, WORKPAPER_CONDITION_ACTIONS } from "@lb/utils";
import { Box, Divider, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { compact, difference, endsWith, every, find, get, has, includes, isEmpty, isEqual, isNull, map, mapValues, some, startsWith, without } from "lodash";
import React, { Children, Fragment, useEffect, useState } from "react";

const operatorFunctions = {
  equals: (a, b) => isEqual(a, b),
  "not-equals": (a, b) => !isEqual(a, b),
  "less-than": (a, b) => a < b,
  "greater-than": (a, b) => a > b,
  "less-than-or-equal-to": (a, b) => a <= b,
  "greater-than-or-equal-to": (a, b) => a >= b,
  contains: (a, b) => every(b, (val) => includes(a, val)),
  "begins-with": (a, b) => startsWith(a, b),
  "ends-with": (a, b) => endsWith(a, b),
  "does-not-contain": (a, b) => !every(b, (val) => includes(a, val)),
  "does-not-begin-with": (a, b) => !startsWith(a, b),
  "does-not-end-with": (a, b) => !endsWith(a, b),
  "is-null": (a) => isNull(a),
  "is-not-null": (a) => !isNull(a),
  in: (a, b) => includes(b, a),
  "not-in": (a, b) => !includes(b, a),
  between: (a, [low, high]) => a >= low && a <= high,
  "not-between": (a, [low, high]) => a < low || a > high,
};

const evaluateRule = (rule, variableValues) => {
  const operatorFunction = operatorFunctions[rule.operator];
  //! fallback
  if (!operatorFunction) return false;

  return operatorFunction(variableValues[rule.source], rule.value);
};

const validateRules = (ruleGroup, variableValues) => {
  const conjunction = ruleGroup.conjunction;
  const rules = ruleGroup.rules;

  if (conjunction === "and") {
    return every(rules, (rule) => (rule.rules ? validateRules(rule, variableValues) : evaluateRule(rule, variableValues)));
  } else if (conjunction === "or") {
    return some(rules, (rule) => (rule.rules ? validateRules(rule, variableValues) : evaluateRule(rule, variableValues)));
  }

  return false;
};

export default function WorkPage({ variables, workflows, name }) {
  const [hiddenFields, setHiddenFields] = useState([]);
  const [altOptions, setAltOptions] = useState({});
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    map(workflows, (workflow) => {
      const depopulatedValues = depopulateWorkpaper(get(values, name));

      let SHOULD_EXECUTE_ACTION = validateRules(workflow, depopulatedValues);

      if (SHOULD_EXECUTE_ACTION) {
        map(workflow.actions, ({ action, target, payload }) => {
          let targetVariable = find(variables, {
            name: target,
          });

          switch (action?.value || action) {
            case WORKPAPER_CONDITION_ACTIONS.key.SHOW:
              setHiddenFields((prev) => [...new Set(without(prev, targetVariable.name))]);
              break;

            case WORKPAPER_CONDITION_ACTIONS.key.HIDE:
              setFieldValue(`${name}.${target}`, "");
              setHiddenFields((prev) => [...new Set([...prev, targetVariable.name])]);
              break;

            case WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS:
              if (targetVariable.type === VARIABLE_TYPES.key.MCQ) {
                if (targetVariable.multiple) {
                  //! if target value is not in the payload, i.e options
                  if (get(values, name)[target] && !isEmpty(difference(map(get(values, name)[target], "value"), payload))) {
                    setFieldValue(`${name}.${target}`, "");
                  }
                } else {
                  if (get(values, name)[target]?.value && !includes(payload, get(values, name)[target]?.value)) {
                    setFieldValue(`${name}.${target}`, "");
                  }
                }
              }

              setAltOptions((prev) => ({
                ...prev,
                [targetVariable.name]: payload,
              }));
              break;

            case WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE:
              if (!includes(hiddenFields, target)) {
                let val = targetVariable.type === VARIABLE_TYPES.key.MCQ ? (targetVariable.multiple ? map(payload, (v) => ({ label: v, value: v })) : { label: payload, value: payload }) : payload;

                if (!isEqual(get(values, name)[target], val)) {
                  setFieldValue(`${name}.${target}`, val);
                }
              }
              break;

            default:
              break;
          }
        });
      } else {
        map(workflow.altActions, ({ action, target, payload }) => {
          let targetVariable = find(variables, {
            name: target,
          });

          switch (action?.value || action) {
            case WORKPAPER_CONDITION_ACTIONS.key.SHOW:
              setHiddenFields((prev) => [...new Set(without(prev, targetVariable.name))]);
              break;

            case WORKPAPER_CONDITION_ACTIONS.key.HIDE:
              setFieldValue(`${name}.${target}`, "");
              setHiddenFields((prev) => [...new Set([...prev, targetVariable.name])]);
              break;

            case WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS:
              if (targetVariable.type === VARIABLE_TYPES.key.MCQ) {
                if (targetVariable.multiple) {
                  //! if target value is not in the payload, i.e options
                  if (get(values, name)[target] && !isEmpty(difference(map(get(values, name)[target], "value"), payload))) {
                    setFieldValue(`${name}.${target}`, "");
                  }
                } else {
                  if (get(values, name)[target]?.value && !includes(payload, get(values, name)[target]?.value)) {
                    setFieldValue(`${name}.${target}`, "");
                  }
                }
              }

              setAltOptions((prev) => ({
                ...prev,
                [targetVariable.name]: payload,
              }));
              break;

            case WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE:
              if (!includes(hiddenFields, target)) {
                let val = targetVariable.type === VARIABLE_TYPES.key.MCQ ? (targetVariable.multiple ? map(payload, (v) => ({ label: v, value: v })) : { label: payload, value: payload }) : payload;

                if (!isEqual(get(values, name)[target], val)) {
                  setFieldValue(`${name}.${target}`, val);
                }
              }
              break;

            default:
              break;
          }
        });
      }
    });
  }, [values, variables, workflows]);

  return (
    <Fragment>
      <Stack spacing={3}>
        {Children.toArray(
          map(variables, (variable, VARIABLE_INDEX) => {
            const variableOptions = altOptions[variable.name] || variable.options;

            if (includes(hiddenFields, variable.name)) return null;
            return (
              <Box justifyContent="space-between" spacing={3} alignItems={"center"} divider={<Divider flexItem orientation="vertical" variant="middle" />}>
                <Typography variant="body2" fontWeight="bolder" color="text.primary" textAlign="justify" display="block">
                  {variable.label}
                </Typography>

                {variable.description && <FormHelperText sx={{ ml: 0 }}>{variable.description}</FormHelperText>}

                {
                  {
                    text: (
                      <LbTextArea
                        inputProps={{
                          minRows: 2,
                        }}
                        name={`${name}.${variable.name}`}
                        placeholder={variable.placeholder}
                        boxProps={{ mt: 1 }}
                      />
                    ),

                    mcq: (
                      <LbSmartSelect
                        multiple={variable.multiple}
                        selectAll={variable.selectAll}
                        name={`${name}.${variable.name}`}
                        placeholder={variable.placeholder}
                        options={map(variableOptions, (option) => ({
                          label: option === "*" ? "Select All (*)" : option,
                          value: option,
                        }))}
                        sx={{ mt: 1 }}
                        fullWidth={false}
                      />
                    ),

                    number: <LbNumberField min={variable.min} max={variable.max} fullWidth name={`${name}.${variable.name}`} placeholder={variable.placeholder} sx={{ mt: 1 }} />,

                    static: (
                      <TextField
                        value={variable.value}
                        fullWidth
                        slotProps={{
                          input: { readOnly: true },
                          htmlInput: { "aria-readonly": true },
                        }}
                        name={`${name}.${variable.name}`}
                        placeholder={variable.placeholder}
                        size="small"
                        sx={{ mt: 1 }}
                      />
                    ),
                  }[variable.type]
                }
              </Box>
            );
          })
        )}
      </Stack>
    </Fragment>
  );
}
