import { Stack, useTheme } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import { get, isEmpty, map, split } from "lodash";
import React, { Children, Fragment } from "react";
import RuleFields from "./RuleFields";
import RuleHeader from "./RuleHeader";
import { LbAlert } from "@lb/frontend";
import { FiTrash2 } from "react-icons/fi";
import swal from "sweetalert";

function Rules({ name, removeRule, backgroundColor }) {
  const theme = useTheme();
  const { values } = useFormikContext();
  const rule = get(values, name);

  const connectorColor = [
    "#0047AB",
    "#DC143C",
    "#FF8C00",
    "#228B22",
    "#663399",
    "#40E0D0",
    "#D5006D",
    "#333333",
    "#00BFFF",
    "#FF0000",
  ][split(name, ".rules.")?.length - 1];

  if (isEmpty(rule?.rules))
    return <RuleFields name={name} removeRule={removeRule} connectorColor={connectorColor} />;

  return (
    <fieldset
      style={{
        borderTopLeftRadius: 8,
        borderLeftWidth: 2,
        borderStyle: "dashed",
        borderColor: connectorColor,
        borderRight: 0,
        borderBottom: 0,
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 0,
        backgroundColor,
      }}
    >
      <FieldArray
        name={`${name}.rules`}
        render={({ push: pushRule, remove: removeRuleGroup }) => {
          return (
            <Fragment>
              <legend
                style={{
                  width: "100%",
                }}
              >
                <RuleHeader
                  name={name}
                  connectorColor={connectorColor}
                  pushRule={() => pushRule({ source: "", operator: "", value: "" })}
                  pushRuleGroup={() =>
                    pushRule({
                      conjunction: "and",
                      rules: [{ source: "", operator: "", value: "" }],
                    })
                  }
                  removeRule={removeRule}
                />
              </legend>
              <Stack pl={2} spacing={3}>
                {Children.toArray(
                  map(rule?.rules, (rule, RULE_INDEX) => {
                    return (
                      <Rules
                        name={`${name}.rules.${RULE_INDEX}`}
                        removeRule={
                          RULE_INDEX >= 1
                            ? () =>
                                LbAlert({
                                  title: "Warning",
                                  buttons: ["Cancel", { text: "Confirm", closeModal: false }],
                                  message: "Confirm to delete?",
                                  danger: true,
                                  icon: FiTrash2,
                                }).then(async (willDelete) => {
                                  if (willDelete) {
                                    removeRuleGroup(RULE_INDEX);
                                  }
                                  swal.close();
                                })
                            : null
                        }
                      />
                    );
                  })
                )}
              </Stack>
            </Fragment>
          );
        }}
      />
    </fieldset>
  );
}

export default Rules;
